@use "../../../shared/styles/_variables.scss" as v;
@use "../../../shared/styles/mixins.scss" as m;

.detailed-task {
  width: 1055px;
  // max-height: 70vh;
  height: 682px;
  background-color: v.$white-color;
  @include m.rounded-corners();

  &-section {
    // @include m.one-hundred();
    @include m.flex(column);
    @include m.fonts-basis($size: 1.6rem, $weight: 500);
    // height: 100%;
    
    &__header {
      @include m.fonts-basis(
        $size: 1.4rem,
        $weight: 500,
        $color: v.$placeholder-color
      );
      margin-bottom: 0.7rem;
    }

    &__block {
      justify-self: stretch;
      margin-bottom: 1.6rem;
    }

    &__inner-block {
      @include m.flex($justify: start, $align: center);
      gap: 2rem;

      &_column {
        @include m.flex(column);
      }
    }
  }

  &-district {
    padding: 0.6rem 2.5rem;
    @include m.fonts-basis($size: 1.6rem, $weight: 500);
    @include m.rounded-corners(33px);
    background-color: #5690a3;
    margin-right: 1.2rem;
  }

  &-dates {
    @include m.fonts-basis($size: 1.4rem, $weight: 600);
  }

  &-main {
    display: grid;
    // max-height: 100%;
    grid-template-columns: 1fr 1fr;
    padding: 1.5rem 5rem;
    column-gap: 7.1rem;
  }

  &_box-shadow {
    box-shadow: 2px 4px 10px 1px v.$main-dark-color-shadow;
  }

  &-staff {
    width: 100%;
    font-size: 1rem;

    &__list {
      max-height: 12.8rem;
      padding: 0.5rem 0.5rem 1rem;
      overflow-y: auto;
      @include m.flex($justify: start, $wrap: wrap);
      gap: 0.5rem;
    }

    &__item {
      padding: 0.5rem 1rem;
      @include m.min-max-width(10rem, 12.3rem);
      @include m.flex($align: center);
      @include m.rounded-corners(34px);
      background-color: v.$main-light-color;
      box-shadow: 0px 5px 10px 1px v.$main-dark-color-shadow;
    }

    &__avatar {
      @include m.rounded-corners(50%);
    }

    &__color-legend {
      margin-top: 1rem;

      &-color {
        display: inline-block;
        margin-right: 0.5rem;
        @include m.square_obj(1.2rem, 2px);
        @include m.border($width: 2px, $color: v.$main-dark-color-hover);
      }
    }
  }

  &-equipments {
    width: 100%;

    &__list {
      max-height: 8.8rem;
      padding-right: 1rem;
      padding-bottom: 1rem;
      overflow-y: auto;
      @include m.flex($justify: start, $wrap: wrap);
      gap: 0.5rem;
    }

    &__item {
      padding: 1.1rem 1.2rem;
      @include m.fonts-basis($size: 1.2rem, $weight: 400);
      @include m.min-max-width(7rem, 9rem);
      @include m.flex($align: center);
      @include m.rounded-corners(20px);
      background-color: v.$main-light-color;

      &__icon {
        @include m.box(2.3rem, 2rem);
      }
    }

    &__avatar {
      @include m.rounded-corners(50%);
    }

    &__color-legend {
      margin-top: 1rem;

      &-color {
        display: inline-block;
        margin-right: 0.5rem;
        @include m.square_obj(1.2rem, 2px);
        @include m.border($width: 2px, $color: v.$main-dark-color-hover);
      }
    }
  }

  &-gpr-button {
    width: 100%;
    background-color: v.$main-dark-color;
    @include m.fonts-basis($size: 1.5rem, $weight: 600, $color: v.$white-color);
    @include m.rounded-corners(28px);
    padding: 1.1rem 2rem;
  }

  &-documentation-buttons {
    margin-left: auto;
  }

  &-documentation-button {
    padding: 0.6rem 2rem;
    margin-right: 0.5rem;
    background-color: v.$main-dark-color;
    @include m.fonts-basis(1.6rem, v.$white-color);
    @include m.rounded-corners(33px);
  }

  &-current-month-plans {
    &__header {
      font-size: 1.0rem;
    }

    .detailed-task-location__value {
      font-size: 1.2rem;
    }
  }

  &-progress-bar {
    &__bar {
      position: relative;
      color: #000000;
      font-size: 13px;
      border-radius: 20px;
      // padding: 10px 20px;
      // width: 100%;
      height: 7px;
      box-shadow: 0px 4px 4px 0px #00000040;
  
      section {
        position: relative;
        z-index: 3;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      
      p {
        margin: 0;
      }
    }
  }


  &-goto-buttons {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
  }

  &-goto-button {
    background-color: v.$main-dark-color;
    @include m.box(4.5rem, $radius: 50%);
  }
  
}

.detailed-task-header {
  height: 6.4rem;
  box-sizing: border-box;
  @include m.flex($align: center);
  width: 100%;
  padding: 1.5rem 2rem;
  background-color: v.$main-light-color;
  @include m.rounded-corners();
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;

  &__middle {
    @include m.flex($align: center);
  }

  &__name {
    @include m.fonts-basis($size: 1.8rem, $weight: 600);
    color: v.$main-dark-color;
    margin-right: 1rem;
  }

  &-status {
    position: relative;
    width: 19rem;
    height: 3.5rem;

    &__field {
      position: relative;
      @include m.flex();
      @include m.rounded-corners(20px);
      padding: 0.8rem 1.5rem;
      background-color: v.$main-dark-color;
      box-shadow: 0px 4px 10px 0px #00000040;
      z-index: 100;

      &_expanded {
        background-color: v.$main-dark-color-hover;
      }
    }

    &__list {
      position: absolute;
      top: 50%;
      left: 0rem;
      padding-top: 2rem;
      z-index: 10;
      width: 100%;
      @include m.rounded-corners(15px);
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      background-color: v.$main-dark-color;
    }

    &__item {
      padding: 1rem 1.5rem;
      @include m.flex($justify: start, $align: center);
      @include m.fonts-basis(
        $size: 1.6rem,
        $weight: 500,
        $color: v.$white-color
      );

      .detailed-task-header-status-field__img {
        padding-right: 1.3rem;
      }

      &:hover {
        background-color: v.$main-dark-color-hover;
      }

      &:last-child {
        border-bottom-right-radius: 15px;
        border-bottom-left-radius: 15px;
      }
    }

    &__img {
      @include m.square_obj();
    }

    &__text {
      margin: 0 0.5rem;
      @include m.fonts-basis(
        $size: 1.6rem,
        $weight: 500,
        $color: v.$white-color
      );
    }
  }
}

.goto-task-in-graph {
  @include m.background-image($color: v.$main-dark-color, $url: "../../../shared/assets/task-in-graph-white-icon.svg", $size: 45%);
  margin-right: 1.5rem;
 }

.goto-contributon {
  @include m.background-image($color: v.$main-dark-color, $url: "../../../shared/assets/contribution-white-icon.svg", $size: 50%);
}
