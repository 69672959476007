@use "../../../shared/styles/variables" as v;
@use "../../../shared/styles/mixins" as m;

.form__error-message {
  @include m.fonts-basis(1.4rem, 500, v.$error-dark-color);
  margin-left: 1rem;
  margin-top: 0.5rem;
}

#form__error-message {
  @include m.fonts-basis(1.4rem, 500, v.$error-dark-color);
  text-align: left;
  margin-left: 1rem;
  margin-top: 0.5rem;
}