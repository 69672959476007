//colors
$main-dark-color: #211b27;
$main-dark-color-shadow: rgba(33, 27, 39, 0.25);
$main-light-color: #f0d07e;
$main-light-color-hover: #f0e4bb;
$light-grey-color: #eeeeee;
$darker-grey-color: #d9d9d9;
$error-light-color: #f18072;
$error-dark-color: #923c46;
$main-dark-color-hover: #9e95a5;
$white-color: #ffffff;
$placeholder-color: rgba(33, 27, 39, 0.7);

//decorative colors
$dark-violet: #716380;
$turquoise: #5690a3;

//border radius
$border-radius: 25px;

//fonts
$font-size: 1.4rem;
$font-family: "Roboto";

//
