.modal-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 575px;
  background-color: rgba(102, 101, 100, 0.7);
  backdrop-filter: blur(1px);
}

.modal-wrapper__modal {
  position: relative;
  width: 65%;
  height: 575px;
  margin: auto;
  background-color: #FFFFFF;
  border-radius: 30px;
}

.modal-wrapper__modal--editing {
  height: 690px;
}

.modal__header-wrapper {
  position: relative;
}

.modal__header {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 15px 0;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  background-color: #F0D07E;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.modal__header--dark {
  color: #ffffff;
  background-color: #211b27;
}

.modal__info-wrapper {
 position: relative;
 display: flex;
 align-items: center;
 justify-content: center;
 width: 100%;
 padding: 0 40px;
}

.modal__equip-image {
  margin-right: 30px;
  margin-left: auto;
}

.modal__name {
  position: relative;
  margin-right: 30px;
  margin-left: auto;
 /* width: 30%; */
}

.modal__name--equip {
  margin-left: 0;
}

.modal__name--dark {
  color: #ffffff;
  background-color: #211b27;
}

.modal__name--imei::before {
  position: absolute;
  top: 50%;
  left: -40px;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  margin-right: 20px;
  background-image: url("../../Images/imei.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  content: '';
}

.modal__name--active::after {
  position: absolute;
  top: 50%;
  left: -90px;
  transform: translateY(-50%);
  content: '';
  width: 10px;
  height: 10px;
  background-color: #73D173;
  border-radius: 50%;
  outline: 2px solid var(--main-dark-color);
}

.modal__name--active:hover::before {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 11;
  transform: translate(-50%, -50%);
  content: 'Был активен в течение прошлой недели';
  width: 100px;
  padding: 10px 15px;
  font-weight: 400;
  font-size: 11px;
  color: #ffffff;
  background-color: #211b27;
  border-radius: 20px;
}

.modal__button-wrapper {
  display: flex;
}

.modal_button {
  /* position: absolute; */
  width: 30px;
  height: 30px;
  margin-right: 0.5rem;
  background-color: var(--main-dark-color);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
  border-radius: 50%;
  box-shadow: 0px 6.61px 13.22px 0px #00000040;
}

.modal_button--edit-modal {
  background-image: url("../../Images/edit-icon.svg");
}

.modal_button--delete-modal {
  left: 40px;
  background-image: url("../../Images/bin.svg");
}

.modal__button--close-modal {
  right: 20px;
  width: 35px;
  height: 35px;
  margin-left: auto;
  background-image: url("../../Images/close-form-cross.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.modal__button--close-modal-white {
  position: absolute;
  top: 5px;
  background-image: url("../../Images/close-cross-white.svg");
}


.information-container {
  display: grid;
  grid-template-columns: 27% 32% 32%;
  grid-template-rows: 155px 120px 160px;
  column-gap: 40px;
  row-gap: 5px;
  margin-top: 60px;
  padding: 25px 40px 25px 40px;
}

.information-container--equip {
    grid-template-rows: 155px 120px 160px;
}

.information-container__map {
  display: flex;
  width: 100%;
  height: 100%;
}

.modal-wrapper__modal--editing .information-container {
  grid-template-rows: 250px 140px 160px;
}

.information-container__avatar {
  grid-area: 1 / 1;
  grid-row: 1 / 1;
}

.information-container__personal-info {
  grid-area: 2 / 2;
  grid-row: 1 / 1;
}

.information-container__other-parameters {
  grid-area: 1 / 1;
  grid-row: 2 / 2;
}


.information-container__maps {
  grid-area: 2 / 2;
  grid-row: 2 / 2;
  margin-top: 10px;
}

.information-container__maps--equip {
  margin-top: -40px;
}

.information-container__productivity-months {
  grid-area: 1 / 1;
  grid-row: 3 / 3;
}

.information-container__productivity-months--equip {
  margin-top: -25px;
}

.information-container__productivity {
  grid-area: 2 / 2;
  grid-row: 3 / 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.information-container__productivity--equip {
  margin-top: -30px;
}

.information-container__current-shift {
  grid-area: 3 / 3;
  grid-row: 1 / 1;
  margin-top: 10px;
}

.information-container__previous-shifts {
  grid-area: 3 / 3;
  grid-row: 2 / -1;
  margin-top: 10px;
}

.information-container__previous-shifts--editing {
  grid-area: 3 / 3;
  grid-row: 1 / 3;
  margin-top: 10px;
}

.personal-info__wrapper {
  display: flex;
  text-align: center;
}

.personal-info__wrapper p {
  margin-right: 15px;
}

.personal-info__input {
  width: 70px;
  height: 25px;
  padding: 0 10px;
  background-color: #D4D2D6;
  border: 2px solid #211b27;
  border-radius: 20px;
}

.personal-info__input--imei {
  width: 90%;
}

.personal-info__topic {
  margin-bottom: 5px;
  font-weight: 500;
  color: rgba(33, 27, 39, 0.4);
}

.information-container__avatar .personal-info__topic,
 .information-container__other-parameters .personal-info__topic {
  margin-right: 10px;
}

.personal-info__topic--editing {
  margin-top: 30px;
}

.personal-info__description {
  margin: 0;
  font-size: 11px;
}

.personal-info__description--emphasized {
  font-size: 15px;
}

.personal-info__button-delete-imei {
  padding: 5px 35px;
  color: #ffffff;
  background-color: #211b27;
  border-radius: 20px;
}

.personal-info__button-safe {
  grid-column: 3 / 3;
  width: 250px;
  height: 35px;
  padding: 10px 0;
  margin-top: 110px;
  margin-left: 50px;
  color: #ffffff;
  background-color: #211b27;
  border-radius: 20px;
}

.personal-info__equip-buttons {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.personal-info__equip-button {
  width: 35px;
  height: 35px;
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: #F0D07E;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 65%;
  border-radius: 50%;
  box-shadow: 0px 3.87px 3.87px 0px #00000040;
}

.personal-info__imei-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.information-container__gallery {
  margin-top: auto;
}

.gallery {
  display: flex;
  align-items: center;
}

.gallery img {
  border-radius: 15px;
  object-fit: contain;
}

.gallery__button {
  width: 40px;
  height: 70px;
  background-repeat: no-repeat;
  background-position: center;
}

.gallery__button--prev {
  background-image: url("../../Images/arrow-prev-dark-blue.svg");
}

.gallery__button--next {
  background-image: url("../../Images/arrow-next-dark-blue.svg");
}

.information-container__personal-info dl {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.information-container__personal-info div {
  width: 45%;
}

.information-container__personal-info .information-container__equiment {
  width: 100%;
}

.information-container__personal-info .information-container__equiment .personal-info__equip-buttons {
  display: flex;
  width: 100%;
  overflow-x: auto;
}

.information-container__personal-info .information-container__equiment dd::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.information-container__personal-info img {
  margin-right: 5px;
}

.information-container__other-parameters dl,
.information-container__avatar dl {
  display: flex;
}

.information-container__map-coordinates-wrapper {
  display: flex;
  justify-content: space-around;
}

.information-container__maps p {
  margin-bottom: 10px;
  font-size: 12px;
  font-weight: 500;
  color: rgba(33, 27, 39, 0.4);
}

.user-leaflet-container,
.equip-leaflet-container {
  position: relative;
  width: 200px;
  height: 85px;
  filter: grayscale(100%);
}

.shift-leaflet-container {
  filter: none;
}

.leaflet-map-opened {
  width: 60vw;
  height: 400px;
  margin: auto;
  filter: grayscale(100%);
}

.information-container__button-open-map {
  position: absolute;
  top: 0;
  width: 200px;
  height: 85px;
  background-color: transparent;
  border: none;
}

.information-container__button-map-close {
  position: absolute;
  right: 5vw;
  bottom: 5vh;
  padding: 10px 25px;
  font-size: 10px;
  background-color: #F0D07E;
  border-radius: 20px;
}

.leaflet-control-attribution,
.leaflet-control-zoom {
  display: none;
}

.information-container__coordinates {
  margin: 0;
}

.information-container__coordinates dt {
  margin-bottom: 5px;
}

.information-container__coordinates div:first-child {
  margin-bottom: 10px;
}

.information-container__productivity-months {
  position: relative;
}

.information-container__productivity-months .personal-info__topic {
  margin-top: 15px;
  margin-bottom: 10px;
}

.information-container__productivity dl {
  margin-left: 10px;
}

.information-container__productivity .personal-info__description {
  margin-bottom: 5px;
}

.information-container__doughnut-wrapper {
  position: relative;
}

.information-container__doughnut-info {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70px;
}

.information-container__doughnut-info p {
  font-size: 9px;
  font-weight: 500;
  color: #211B2799;
}

.information-container__doughnut-info .doughnut-info__num {
  font-size: 26px;
  color: #211b27;
}

.information-container__doughnut-info:first-child  {
  font-size: 60px;
}

.information-container__doughnut-info p {
  text-align: center;
}


.react-calendar-heatmap {
  width: 250px !important;
  height: 125px !important;
}

.react-calendar-heatmap-week rect {
  width: 10px !important;
  height: 10px !important;
}

.react-calendar-heatmap-month-label,
.react-calendar-heatmap-weekday-label {
  font-size: 9px;
}

.color-empty {
  fill: #F0D07E33;
}

.color-scale-1 {
  fill: #F0D07E66;
}

.color-scale-2 {
  fill: #F0D07E99;
}

.color-scale-3 {
  fill: #F0D07ECC;
}

.color-scale-4 {
  fill: #F0D07E;
}

.calendar-rect {
  cursor: pointer;
}

.custom-tooltip {
  width: 260px;
}

.custom-tooltip::before {
  position: absolute;
  content: '';
}

.custom-tooltip__info div {
  width: 100%;
}

.current-shift__container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.current-shift__container div {
  width: 40%;
}

.previous-shifts--equip {
  margin-top: 50px;
}

.previous-shifts__list {
  height: 85%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  overflow-y: auto;
}

.previous-shift__list--editing {
  height: 75%;
}

.previous-shift__list--equip {
  height: 75%;
}

.previous-shifts__list::-webkit-scrollbar {
  width: 15px;
  background: #D4D2D6;
  border-radius: 10px;
  border: 1px solid #D4D2D6;
}

.previous-shifts__list::-webkit-scrollbar-thumb {
  width: 10px;
  background: #211b27;
  border-radius: 15px;
  border: 3px solid transparent;
  background-clip: padding-box;
}

.previous-shifts__item {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.previous-shifts__item .equip-information {
  margin-right: 30px;
}

.previous-shifts__item p{
  margin-right: 15px;
}

.equip-information {
  display: flex;
  align-items: center;
}

.equip-information img {
  margin-right: 10px;
}

.previous-shifts__button {
  width: 25px;
  height: 25px;
  margin-bottom: 5px;
  background-color: #F0D07E;
  background-image: url("../../Images/dots.svg");
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
}

.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 1440px) {
  .modal-wrapper__modal {
    width: 70%;
    overflow-y: auto;
  }

  .modal-wrapper__modal::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  /* .modal__name--imei::before {
    left: 30%;
  } */

  .information-container {
    grid-template-columns: 45% 45%;
    grid-template-rows: 155px 120px 160px 300px;
  }

  .information-container__avatar {
    grid-area: 1 / 1;
    grid-row: 1 / 1;
  }

  .information-container__personal-info {
    grid-area: 2 / 2;
    grid-row: 1 / 1;
  }

  .information-container__other-parameters {
    grid-area: 1 / 1;
    grid-row: 2 / 2;
  }


  .information-container__maps {
    grid-area: 2 / 2;
    grid-row: 2 / 2;
  }

  .information-container__productivity-months {
    grid-area: 1 / 1;
    grid-row: 3 / 3;
  }

  .information-container__productivity {
    grid-area: 2 / 2;
    grid-row: 3 / 3;
  }

  .information-container__current-shift {
    grid-area: 1 / 1;
    grid-row: 4 / 4;
  }

  .information-container__previous-shifts {
    grid-area: 2 / 2;
    grid-row: 4 / 4;
  }

  .information-container__previous-shifts--editing {
    grid-column: 1 / 2;
    grid-row: 4 / 4;
  }

  .personal-info__button-safe {
    grid-area: 2 / 2;
    grid-row: 4 / 4;
    margin-top: 50px;
    margin-right: 20%;
  }

}

@media (max-width: 950px) {
  .modal-wrapper__modal {
    width: 80%;
    overflow-y: auto;
  }

  .modal-wrapper__modal::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  .modal__info-wrapper {
    padding: 0 20px;
  }

  .modal__name {
    margin-right: 10px;
  }

  .modal__name--imei {
    font-size: 11px;
  }

  /* .modal__name--imei::before {
    left: 10%;
  } */

  .information-container {
    grid-template-columns: 100%;
    grid-template-rows: 155px 150px 120px 120px 160px 150px 140px 300px;
  }

  .modal-wrapper__modal--editing .information-container {
    grid-template-columns: 100%;
    grid-template-rows: 255px 255px 100px 120px 160px 140px 0px;
  }

  .information-container__avatar {
    grid-area: 1 / 1;
    grid-row: 1 / 1;
  }

  .information-container__personal-info {
    grid-area: 1 / 1;
    grid-row: 2 / 2;
  }

  .information-container__personal-info--editing {
    margin-top: -30px;
  }

  .information-container__other-parameters {
    grid-area: 1 / 1;
    grid-row: 3 / 3;
  }

  .information-container__other-parameters--editing {
    margin-top: 10px;
  }

  .information-container__maps {
    grid-area: 1 / 1;
    grid-row: 4 / 4;
  }

  .information-container__productivity-months {
    grid-area: 1 / 1;
    grid-row: 5 / 5;

  }

  .information-container__productivity {
    grid-area: 1 / 1;
    grid-row: 6 / 6;
  }

  .information-container__current-shift {
    grid-area: 1 / 1;
    grid-row: 7 / 7;
  }

  .information-container__previous-shifts {
    grid-area: 1 / 1;
    grid-row: 8 / 8;
  }

  .information-container__previous-shifts--editing {
    grid-area: 1 / 1;
    grid-row: 8 / 8;
    margin-top: 20px;
  }

  .personal-info__button-safe {
    grid-area: 1 / 1;
    grid-row: 9 / 9;
    margin-left: 0;
  }

  .information-container__button-map-close {
    right: 10vw;
  }

}
