@use "../../../shared/styles/variables.scss" as v;
@use "../../../shared/styles/mixins.scss" as m;

.equipments-list {
  position: relative;
  color: v.$main-dark-color;
  
  &__field {
    position: relative;
    background-color: v.$main-light-color;
    @include m.fonts-basis($size: 1.7rem, $weight: 600);
    @include m.rounded-corners(28px);
    @include m.flex($align: center);
    padding: 1.5rem 2.8rem;
    z-index: 100;
    box-shadow: 0px 4px 10px 1px v.$main-dark-color-shadow;
  }

  &__search-input {
    box-sizing: border-box;
    width: 100%;
    padding: 0.9rem 1.8rem;
    // margin-right: 1rem;
    @include m.rounded-corners(15px);
    @include m.fonts-basis($size: 1.7rem, $weight: 600);
  }

  &__item {
    @include m.flex($align: center);
    margin-bottom: 0.7rem;
    padding: 0.5rem 2rem;
    background-color: v.$white-color;
    @include m.fonts-basis($size: 1.6rem, $weight: 500);
    @include m.rounded-corners(15px);
  }

  .dropdown-list {
    // @include m.flex($direction: column, $justify: center, $align: center);
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    border: 1px solid black;
    overflow: hidden;
    min-height: 185px;
    max-height: 285px;
    width: 100%;
    background-color: v.$main-light-color;
    z-index: 10;
    border-radius: 0px 0px 25px 25px;
    padding: 5rem 1.5rem 2rem;
    clip-path: inset(0 0 100% 0);
    transition: clip-path 1.5s ease-out;
    
    &--opened {
      clip-path: inset(0 0 0 0); // Показываем блок
    }
    
    &__list {
      max-height: 100%;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 12px;
        background: var(--light-grey);
        border-radius: 10px;
        border: 1px solid var(--darker-grey);
      }
    
      &::-webkit-scrollbar-thumb {
        width: 10px;
        background: var(--main-dark-color);
        border-radius: 15px;
        border: 3.5px solid transparent;
        background-clip: padding-box;
      }
    }

    &__text {
      text-align: center;
      @include m.fonts-basis($size: 1.6rem, $weight: 500);
    }

    &__item {
      position: relative;
      z-index: 1;
      width: 100%;
      @include m.flex($align: center);
      // padding: 0.8rem 2rem;
      @include m.fonts-basis($size: 1.6rem, $weight: 500);

      &:not(:last-child) {
        margin-bottom: 0.7rem;
      }

      &_wrapper {
      @include m.flex($align: center);
      }
    }
  }

  .add-equipment {
    width: 100%;

    &__container {
      @include m.flex(column, start, center);
      height: 100%;
    }

    &__list {
      @include m.flex(row, start);
      flex-wrap: wrap;
      gap: 10px 5px;
    }

    &__equip-button {
      @include m.square_obj(4rem, 50%);
      @include m.background-image($color: null, $size: 60%);

    }
  
    &__radiobutton {
      display: none;
    }

    &__input {
      @include m.border(2px);
      width: 90%;
      padding: 1.5rem;
      @include m.rounded-corners(30px);
      
      &::placeholder {
        @include m.fonts-basis($size: 1.4rem, $weight: 500, $color: v.$placeholder-color);
      }
    }

    &__button {
      width: 80%;
      margin: 2rem 10% 0rem;
      padding: 1.2rem;
      color: v.$white-color !important;
      background-color: v.$main-dark-color;
      @include m.fonts-basis($size: 1.8rem, $weight: 500);
      @include m.rounded-corners(30px);
    }

  }
  .chosen {
    background-color: v.$main-dark-color;
  }
}

.rotated {
  transform: rotate(180deg);
}

.dropdown-list__equip-icon {
  display: inline-block;
  @include m.square_obj(3rem);
  @include m.background-image($size: 100%);
  margin-right: 1rem;
  // background-repeat: no-repeat;
  // background-position: center;
  // background-size: 70%;
}

.equipment-list__item-inner-container {
  @include m.flex($align: center);
}

.equipment-list__item-container {
  position: relative;
}