@use "../../../shared/styles/variables" as vars;
@use "../../../shared/styles/mixins" as mixins;

.projects {
  &__header {
    margin: 0 2.5rem 1.8rem;
  }

  &__button-wrapper {
    justify-self: end;
  }

  &__create-task-button {
    padding: 1rem 2.5rem;
    margin-right: 1rem;
    background-color: vars.$main-light-color;
    @include mixins.fonts-basis(
      $weight: 500,
      $size: 2rem,
      $line-height: 2.3rem
    );
    @include mixins.rounded-corners(25px);

    span {
      @include mixins.fonts-basis(2.5rem);
      margin-right: 1rem;
    }
  }

  &-cards-container {
    margin-top: -2rem;
    height: 110%;
    width: 102%;
    @include mixins.flex($justify: start, $wrap: wrap);
    gap: 2.5rem;
    padding-right: 2rem;
    overflow-x: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 2rem;
    }

    &::-webkit-scrollbar-thumb {
      border: 3.5px solid transparent;
    }
  }
}

.projects-main {
  &__upper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1rem;
    justify-content: space-between;
    align-items: center;
    justify-items: start;
    margin-bottom: 3rem;
  }

  &__main {
    height: 70%;
  }
}

.projects .projects__page-container--dark-bg {
  padding: 4rem 8rem 4.4rem 4rem;
}
