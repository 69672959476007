@use "../../../shared/styles/variables.scss" as vars;
@use "../../../shared/styles/mixins.scss" as mixins;

.add-staff-list {
  // position: relative;
  // color: vars.$main-dark-color;
  
  &__field {
    position: relative;
    background-color: vars.$main-light-color;
    @include mixins.fonts-basis($size: 1.7rem, $weight: 600);
    @include mixins.rounded-corners(28px);
    @include mixins.flex($align: center);
    padding: 1.5rem 2.8rem;
    z-index: 100;
    box-shadow: 0px 4px 10px 1px vars.$main-dark-color-shadow;
  }

  .add-staff-list__item {
    padding: 1.5rem;
    width: 100%;
  }

  &__button {
    width: 80%;
    margin: 2rem 10% 0rem;
    padding: 1.2rem;
    color: vars.$white-color !important;
    background-color: vars.$main-dark-color;
    @include mixins.fonts-basis($size: 1.8rem, $weight: 500);
    @include mixins.rounded-corners(30px);
  }

  &__input {
    @include mixins.border(2px);
    width: 90%;
    padding: 1.5rem;
    @include mixins.rounded-corners(30px);
    
    &::placeholder {
      @include mixins.fonts-basis($size: 1.4rem, $weight: 500, $color: vars.$placeholder-color);
    }
  }

  .add-equipment {
    width: 100%;

    &__container {
      @include mixins.flex(column, start, center);
      height: 100%;
    }

    &__list {
      @include mixins.flex(row, start);
      flex-wrap: wrap;
      gap: 10px 5px;
    }

    &__equip-button {
      @include mixins.square_obj(4rem, 50%);
      @include mixins.background-image($color: null, $size: 60%);

    }
  
    &__radiobutton {
      display: none;
    }
  }

  .chosen {
    background-color: vars.$main-dark-color;
  }
}

.rotated {
  transform: rotate(180deg);
}