@use '../../../shared/styles/variables' as vars;

.start-menu {
  width: 100%;
  height: 90%;
  padding: 40px 0;
  background-color: vars.$main-dark-color;

  &__container {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__wrapper {
    display: flex;
    margin: auto;
    width: 100%;
    max-width: 1440px;
    height: 90%;
  }

  &__logout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__navigation {
    flex: 1;
  }
}
