@use "../../../shared/styles/_variables.scss" as vars;
@use "../../../shared/styles/mixins.scss" as mixins;

.filters {
  height: 7rem;
  width: 100%;

  &__filter-button {
    @include mixins.box(7rem);
    border-radius: 0;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    @include mixins.background-image($color: vars.$main-light-color, $url: "../../../shared/assets/filter-black-icon.svg", $size: 40%, $position: 2.5rem 1.5rem);
  }
}