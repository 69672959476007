:root {
  --main-dark-color: #211b27;
  --main-light-color: #F0D07E;
  --light-grey: #eeeeee;
  --darker-grey: #D4D2D6;
  --error: #a7434f;
  --period-filter:#4a1e0d;
  --opened-form-width: 30vw;
}

.filter {
  position: relative;
  width: 96.5%;
  height: 50px;
  margin-left: 8px;
  display: flex;
  background-color: var(--darker-grey);
  border-radius: 5px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.filter-mobile {
  margin-left: 3px;
  height: 50px;
}

.button-filter {
  position: absolute;
  top: 0;
  left: 0;
  width: 70px;
  height: 50px;
  background-color: var(--main-light-color);
  background-image: url("../../Images/filter-icon-black.svg");
  background-repeat: no-repeat;
  background-position: top 5px right 25px;
  background-size: 30%;
  border: none;
  border-radius: 5px;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.categories-wrapper {
  position: absolute;
  top: 1px;
  left: 3px;
  z-index: 1;
  width: 250px;
  margin: 0;
  background-color: var(--main-dark-color);
  border-radius: 30px;
}

.categories-list {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  list-style: none;
}

.categories-items button{
  width: 100%;
  padding: 15px 30px;
  color: #ffffff;
  background-color: transparent;
  border: none;
}

.categories-items:first-child button {
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.categories-items:last-child button {
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.categories-items button:hover{
  background-color: #9E95A5;
}

.filter-wrapper {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  margin-right: auto;
  background-color: #FBDF92;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.filter-wrapper-mobile {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  margin-right: auto;
  background-color: #FBDF92;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.filter-category {
  display: none;
  align-self: center;
  margin: 0 10px;
  text-align: center;
  transition: display;
}

.filter-category-type-mobile {
  margin-right: auto;
}

.filter-wrapper-mobile .technic-type-list-mobile{
  margin: 10px auto;
}

.filter-input {
  width: 0;
  padding: 0;
  background-color: #FBDF92;
  border: none;
  transition: width 0.5s;
  overflow: hidden;
}

.filter-input:focus {
  outline: none;
}

.filter-input:active {
  outline: none;
}

.expanded-category {
  display: block;
}

.expanded-input {
  width: 150px;
  background-color: transparent;
}

.technic-type-list {
  display: none;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
  transition: display;
}

.technic-type-list-mobile {
  justify-content: center;
  width: 300px;
}

.expanded-type-list{
  display: flex;
}

.technic-type-item{
  margin-right: 5px;
}

.technic-type-item:last-child{
  margin-right: 0;
}

.technic-type-item .technic-type-button {
  width: 50px;
  height: 30px;
  background-color: rgba(255, 250, 221, 0.7);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  border: none;
  border-radius: 10px;
}

.technic-type-item .technic-type-button:hover {
  background-color: rgba(255, 250, 221, 0.3);
}


.technic-type-item .technic-type-button:active {
  background-color: rgba(255, 250, 221, 0);
}

.technic-type-item .technic-type-excavator{
  background-image: url("../../Images/Equipment/excavator_32.png");
}

.technic-type-item .technic-type-bulldozer{
  background-image: url("../../Images/Equipment/bulldozer_32.png");
}

.technic-type-item .technic-type-crane{
  background-image: url("../../Images/Equipment/crane_32.png");
}

.technic-type-item .technic-type-grader{
  background-image: url("../../Images/Equipment/grader_32.png");
}

.technic-type-item .technic-type-rink{
  background-image: url("../../Images/Equipment/rink_32.png");
}

.technic-type-item .technic-type-truck{
  background-image: url("../../Images/Equipment/truck_32.png");
}

.technic-type-item .technic-type-pooker{
  background-image: url("../../Images/Equipment/camera_10.png");
}

.technic-type-item .technic-type-auto{
  background-image: url("../../Images/Equipment/auto_32.png");
}

.technic-type-item .technic-type-refueler{
  background-image: url("../../Images/Equipment/refueler_512.png");
}

.filter-item-technic-type{
  display: flex;
  align-items: center;
}

.technic-type-category{
  margin: 0;
  margin-right: 15px;
}

.technic-type-chosen{
  display: block;
  margin-right: 5px;
}

.input-date {
  font-family: "Roboto Mono", sans-serif;
  font-size: 13px;
  font-weight: 400;
}

.filter__applied-filter-wrapper {
  position: absolute;
  top: 0;
  left: 80px;
  display: flex;
  margin: 0;
  padding: 0;
  width: 55vw;
  list-style-type: none;
  padding-right: 50px;
  height: 50px;
  overflow: hidden;
  overflow-x: auto;
  border-radius: 30px;
}

.filter-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style-type: none;
  border-radius: 10px;
  overflow: hidden;
}

.filter-item {
  align-self: center;
  margin-right: 10px;
  padding: 5px 15px 25px 20px;
  text-align: center;
  border-radius: 30px;
  white-space: nowrap;
}

.filter-item button {
  background-color: transparent;
  border: none;
}

.filter-component-wrapper {
  position: absolute;
  top: -5px;
  left: -5px;
  z-index: 10;
}

.filter-category-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  padding: 40px 30px 30px;
  border-radius: 30px;
}

.filter-category-wrapper-mobile {
  width: 75%;
}

.filter-category-wrapper__close-button {
  position: absolute;
  top: 10px;
  right: 15px;
  z-index: 10;
  width: 30px;
  height: 30px;
  background-color: transparent;
  background-image: url('../../Images/cross.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.filter-category-wrapper__type-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 10px;
}

.filter-category-wrapper__period-wrapper {
  position: relative;
  border: 2px #4a1e0d solid;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: .6rem 1.7rem 0.1rem;
  margin: .5rem 0;
}

.filter-category__close-btn {
  position: absolute;
  /* box-sizing: border-box; */
  top: 7%;
  right: 2%;
  z-index: 10;
  width: .5rem;
  height: .5rem;
  padding: .7rem;
  background-color: transparent;
  background-image: url('../../Images/cross.svg');
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 20px;
  background-size: 100%;
}

.filter-category__close-btn:hover {
  background-color: rgb(179, 70, 27, 0.5);
}

.filter-category-wrapper__input {
  width: 250px;
  padding: 10px;
  margin-bottom: 0.5rem;
  border-radius: 30px;
  border: none;
}

.filter-category-wrapper-mobile .filter-category-wrapper__input {
  width: 95%;
  margin-top: 5px;
  margin-bottom: 20px;
}

.filter-category-wrapper__button {
  width: 150px;
  padding: 10px;
  color: #ffffff;
  background-color: var(--main-dark-color);
  border-radius: 30px;
}

.filter-category-wrapper-mobile .filter-category-wrapper__button {
  width: 70%;
}

.filter-category-dates__add-button {
  position: relative;
  border-radius: 50%;
  border: 3px var(--main-dark-color) solid;
  padding: .6rem;
  background: url("../../Images/plus-black.svg") no-repeat center;
  background-size: 80%;
  margin-bottom: .6rem;
  /* filter: invert(14%) sepia(8%) saturate(7485%) hue-rotate(342deg) brightness(95%) contrast(98%); */
}
