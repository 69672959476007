:root {
  --main-dark-color: #211b27;
  --main-dark-color-shadow: rgba(33, 27, 39, 0.25);
  --main-light-color: #F0D07E;
}


@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("./Fonts/Roboto-Regular.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("./Fonts/Roboto-Medium.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url("./Fonts/Inter-Regular.woff2") format("woff2");
  font-display: swap;
}

html {
  height: 100%;
}

body {
  position: relative;
  margin: 0;
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 400;
  background-color: #eeeeee;
}

.body-wrapper {
  display: flex;
  height: 100vh;
  width: 100vw;
  background-color: #eeeeee;
}

.body-wrapper-mobile {
  margin-top: 30px;
}

.page-flex-wrapper {
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #eeeeee;
}

.page-flex-wrapper-mobile {
  flex-direction: column-reverse;
  justify-content: center;
  height: 95vh;
}

.main-index {
  flex: 1 1;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  height: 95vh;
  width: 100%;
  max-height: 1200px;
}

.page-flex-table-wrapper-mobile {
  position: relative;
  flex-direction: column-reverse;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
}

.main-index__wrapper-mobile {
  flex-direction: column-reverse;
}

.data-table-flex-wrapper {
  width: 95vw;
  overflow: hidden;
}

.sticky-footer-wrapper {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
  margin: 0;
}

.filter-table-wrapper-desktop {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 90%;
  padding-top: 40px;
  height: 100%;
}

.filter-table-wrapper-mobile {
  width: 90%;
  margin: 10vh auto;
  overflow-y: auto;
  overflow-x: hidden;
 }

.main-container {
  flex-grow: 1;
}

body.overlay-open {
  overflow: hidden;
}

.inactive {
  display: none;
}

button {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: #000000;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

input {
  font-family: "Roboto";
  font-size: 13px;
  -webkit-text-size-adjust: 100%;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.page-footer {
  width: 100%;
  height: 60px;
  background-color: #FCBA03;
}

.download-excel {
  position: relative;
}

.download-excel__button {
  box-sizing: border-box;
  padding: 0.7rem 1.5rem;
  padding-right: 3.5rem;
  margin-right: 30px;
  font-size: 11px;
  font-weight: 500;
  border-radius: 30px;
  background: var(--main-light-color) url("./Images/download-black-icon.svg") no-repeat 85%;
  background-size: 15px;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.download-excel__message {
  position: absolute;
  bottom: -20px;
}

.table-footer__wrapper-mobile .download-excel__button {
  box-sizing: border-box;
  padding: 10px 20px;
  padding-right: 40px;
  font-size: 9px;
  font-weight: 500;
  border-radius: 30px;
  background: var(--main-light-color) url("./Images/download-black-icon.svg") no-repeat 85%;
  background-size: 10px;
}

.page-size-editor {
  display: flex;
  align-items: center;
  padding: 0.5rem 1.8rem;
  font-size: 11px;
  font-weight: 500;
  border-radius: 30px;
  background: var(--main-light-color);
  background-size: 15px;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.page-size-editor__button {
  position: relative;
  margin-left: 25px;
  padding: 5px 20px 5px 10px;
  font-size: 11px;
  color: #ffffff;
  background-color: var(--main-dark-color);
  border-radius: 5px;
  cursor: pointer;
}

.page-size-list {
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  list-style-type: none;
  background-color: var(--main-dark-color);
  border-radius: 5px;
}

.page-size-item button{
  padding: 5px 20px 5px 10px;
  font-size: 11px;
  color: #ffffff;
}

.page-size-item:hover {
  background-color: #9E95A5;
}

.page-size-item:first-child:hover {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.page-size-item:last-child:hover {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.table-footer__wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.table-footer__wrapper-mobile {
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  padding: 0 15px;
  margin-bottom: 30px;
}

.table-footer__wrapper-mobile .page-size-editor {
  padding: 0.5rem 1rem;
  width: 40%;
  font-size: 9px;
}

.table-footer__wrapper-mobile .page-size-editor__button {
  margin-left: 10px;
}

.table-footer__wrapper-mobile .pagination {
  order: -1;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-left: 0;
}

.table-footer__wrapper-mobile .download-excel {
  order: 1;
}

.table-footer__wrapper-mobile .download-excel__button {
  margin-right: 0;
}

/*HEADER*/

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 28px;
  padding: 36px 10%;
  background-color: #FCBA03;
}

.logo {
  color: #000000;
  text-decoration-line: none;
}

/* .filter-desktop {
  display: flex;
  background-color: rgba(251, 223, 146, 0.3);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.filter-mobile {
  display: flex;
  background-color: rgba(251, 223, 146, 0.3);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
} */

.button-yellow {
  padding: 20px 20px;
  background-color: transparent;
  border: none;
  border-radius: 10px;
}

/* .button-yellow:hover {
  background-color: rgba(253, 238, 217, 0.35);
}

.button-yellow:active {
  background-color: rgba(253, 238, 217, 0.25);
} */

/* .filter-wrapper {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  margin-right: auto;
  background-color: #FBDF92;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.filter-wrapper-mobile {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  margin-right: auto;
  background-color: #FBDF92;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.filter-category {
  display: none;
  align-self: center;
  margin: 0 10px;
  text-align: center;
  transition: display;
}

.filter-category-type-mobile {
  margin-right: auto;
}

.filter-wrapper-mobile .technic-type-list-mobile{
  margin: 10px auto;
}

.filter-input {
  width: 0;
  padding: 0;
  background-color: #FBDF92;
  border: none;
  transition: width 0.5s;
  overflow: hidden;
}

.filter-input:focus {
  outline: none;
}

.filter-input:active {
  outline: none;
}

.expanded-category {
  display: block;
}

.expanded-input {
  width: 150px;
  background-color: transparent;
}

.technic-type-list {
  display: none;
  flex-wrap: wrap;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
  transition: display;
}

.technic-type-list-mobile {
  justify-content: center;
  width: 300px;
}

.expanded-type-list{
  display: flex;
}

.technic-type-item{
  margin-right: 5px;
}

.technic-type-item:last-child{
  margin-right: 0;
}

.technic-type-item .technic-type-button {
  width: 50px;
  height: 30px;
  background-color: rgba(255, 250, 221, 0.7);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  border: none;
  border-radius: 10px;
}

.technic-type-item .technic-type-button:hover {
  background-color: rgba(255, 250, 221, 0.3);
}


.technic-type-item .technic-type-button:active {
  background-color: rgba(255, 250, 221, 0);
}

.technic-type-item .technic-type-excavator{
  background-image: url("./Images/Equipment/excavator_32.png");
}

.technic-type-item .technic-type-bulldozer{
  background-image: url("./Images/Equipment/bulldozer_32.png");
}

.technic-type-item .technic-type-crane{
  background-image: url("./Images/Equipment/crane_32.png");
}

.technic-type-item .technic-type-grader{
  background-image: url("./Images/Equipment/grader_32.png");
}

.technic-type-item .technic-type-rink{
  background-image: url("./Images/Equipment/rink_32.png");
}

.technic-type-item .technic-type-truck{
  background-image: url("./Images/Equipment/truck_32.png");
}

.technic-type-item .technic-type-pooker{
  background-image: url("./Images/Equipment/camera_10.png");
}

.technic-type-item .technic-type-auto{
  background-image: url("./Images/Equipment/auto_32.png");
}

.technic-type-item .technic-type-refueler{
  background-image: url("./Images/Equipment/refueler_512.png");
}

.filter-item-technic-type{
  display: flex;
  align-items: center;
}

.technic-type-category{
  margin: 0;
  margin-right: 15px;
}

.technic-type-chosen{
  display: block;
  margin-right: 10px;
}

.input-date {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  font-weight: 400;
}

.filter-list {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  list-style: none;
  border-radius: 10px;
  overflow-x: auto;
}

.filter-item {
  align-self: center;
  margin: 5px;
  padding: 5px 5px 5px 10px;
  text-align: center;
  background-color: rgba(255, 250, 221, 0.5);
  border-radius: 10px;
  white-space: nowrap;
}

.filter-item button {
  background-color: transparent;
  border: none;
} */

.download-mobile .button-yellow {
  padding: 20px;
}

.logout-download-desktop .button-yellow {
  padding: 10px 20px;
}

.button-apply-desktop {
  align-self: center;
  margin-left: 15px;
  padding: 10px 5px;
  background-color: rgba(255, 250, 221, 0.5);
  transition: background-color 0.3s;
}

.button-apply-desktop.apply-changed {
  background-color:rgba(252, 186, 3, 0.5);
}

.button-download-desktop{
  margin-right: 30px;
}

.button-filter {
  padding: 20px 20px;
  background-color: #FBDF92;
  background-image: url("./Images/icons8-plus.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
  border: none;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.button-download {
  margin-right: 10px;
  background-image: url("./Images/download.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
}

.button-logout {
  background-image: url("./Images/logout.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
}

/* .categories-wrapper {
  position: absolute;
  top: 110%;
  left: 0;
  z-index: 1;
  width: 190px;
  margin: 0;
  background-color: #FBDF92;
  border-radius: 10px;
}

.categories-wrapper-mobile {
  position: absolute;
  top: 110%;
  left: 0;
  z-index: 1;
  width: 190px;
  margin: 0;
  background-color: #FBDF92;
  border-radius: 10px;
}

.categories-list {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 10px;
  list-style: none;
}

.categories-items button{
  width: 100%;
  padding: 10px 30px;
  background-color: transparent;
  border: none;
  border-radius: 5px;
}

.categories-items button:hover{
  background-color: #FBF3DA;
} */


/*MAIN TABLE DESKTOP*/

.button-yellow-dark {
  padding: 20px;
  background-color: rgba(250, 213, 110, 0.5);
  border: none;
  border-radius: 10px;
}

.filtered-data-not-found {
  text-align: center;
}

.loading-data-shift-table {
  position: relative;
  top: -15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 96%;
  height: 70vh;
  background-color: #ffffff;
  outline: 3px solid #bebebe;
  margin-left: 10px;
  border-radius: 30px;
}

.loading-data-shift-table p {
  font-size: 20px;
  text-align: center;
}

.table-loading {
  width: 97%;
  height: 60vh;
  margin-bottom: 80px;
  background-color: rgba(217, 217, 217, 0.5);
  border-radius: 10px;
}

.loading-message {
  text-align: center;
}

.main-table .table {
  box-sizing: border-box;
  width: 100%;
  height: 70vh;
  margin-top: -20px;
  margin-bottom: 10px;
  text-align: center;
  /* border-collapse: collapse; */
  /* overflow-y: auto; */
  overflow-x: hidden;
}

.main-table-mobile .table {
  box-sizing: border-box;
  width: 105%;
  margin-top: -20px;
  margin-bottom: 20px;
  margin-left: -10px;
}

.table::-webkit-scrollbar {
  width: 15px;
  background: #D4D2D6;
  border-radius: 10px;
  border: 1px solid var(--darker-grey);
}

.table::-webkit-scrollbar-thumb {
  width: 10px;
  background: #211b27;
  border-radius: 15px;
  border: 3px solid transparent;
  background-clip: padding-box;
}

.main-table-mobile .table::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.table-head--dark {
  position: relative;
  width: 100%;
  height: 20px;
  max-height: 30px;
  /* margin-bottom: 1rem; */
  padding: 25px 50px 25px 10px;
  font-size: 12px;
  color: #ffffff;
  background-color: var(--main-dark-color);
  border-radius: 30px;
}


.table-head--dark.table-head-mobile{
  position: fixed;
  top: 30px;
  left: 50%;
  z-index: 10;
  transform: translateX(-50%);
  width: 90%;
  margin: 0 auto;
}

.table-head--dark .table-head-item {
  position: relative;
  height: 30px;
  padding-bottom: 7px;
  vertical-align: bottom;
}

.table-head--dark .table-head-item p {
  position: relative;
  margin-right: 1px;
}

.table-head--dark .table-head-item {
  height: 20px;
  padding-bottom: 0;
}

.table-head--dark .table-head-item:first-child {
  width: 2%;
}

.table-head--dark .table-head-item:nth-child(2) {
  width: 10%;
}

.table-head--dark .table-head-item:nth-child(3) {
  width: 6%;
}

.table-head--dark .table-head-item:nth-child(4) {
  width: 10%;
}

.table-head--dark .table-head-item:nth-child(5) {
  width: 7%;
}

.table-head--dark .table-head-item:nth-child(6) {
  width: 7%;
}

.table-head--dark .table-head-item:nth-child(7) {
  width: 5%;
}

.table-head--dark .table-head-item:nth-child(8) {
  width: 6%;
}

.table-head--dark .table-head-item:nth-child(9){
  width: 9%;
}

.table-head--dark .table-head-item:nth-child(10) {
  width: 10%;
}

.table-head--dark .table-head-item:nth-child(11){
 width: 10%;
}

.table-head--dark .table-head-item:nth-child(12){
  width: 7.5%;
 }

.table-head--dark .table-head-item:nth-child(13){
  width: 7.5%;
 }

.table-head-mobile .table-head-item:nth-child(2) {
  width: 30%;
}

.table-head-mobile .table-head-item:nth-child(3),
.table-head-mobile .table-head-item:nth-child(4),
.table-head-mobile .table-head-item:nth-child(5),
.table-head-mobile .table-head-item:nth-child(6),
.table-head-mobile .table-head-item:nth-child(7),
.table-head-mobile .table-head-item:nth-child(8),
.table-head-mobile .table-head-item:nth-child(9),
.table-head-mobile .table-head-item:nth-child(10),
.table-head-mobile .table-head-item:nth-child(11),
.table-head-mobile .table-head-item:nth-child(12),
.table-head-mobile .table-head-item:nth-child(13) {
  width: 50%;
}

.table-head--dark .table-head-item:nth-child(2),
.table-head--dark .table-head-item:nth-child(3),
.table-head--dark .table-head-item:nth-child(4),
.table-head--dark .table-head-item:nth-child(7),
.table-head--dark .table-head-item:nth-child(8),
.table-head--dark .table-head-item:nth-child(10) {
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-head-mobile .table-head-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-head--dark .table-head-item:nth-child(2) span,
.table-head--dark .table-head-item:nth-child(3) span,
.table-head--dark .table-head-item:nth-child(4) span,
.table-head--dark .table-head-item:nth-child(7) span,
.table-head--dark .table-head-item:nth-child(8) span,
.table-head--dark .table-head-item:nth-child(10) span {
  width: 10px;
  height: 8px;
  margin-top: 2px;
  margin-left: 3px;
  background-color: #716BC5;
  border-radius: 2px;
}

.table-head--dark .table-head-item:nth-child(3) span {
  background-color: #F0D164;
}

.table-head--dark .table-head-item:nth-child(4) span {
  background-color: #E5A162;
}

.table-head--dark .table-head-item:nth-child(7) span {
  background-color: #5690A3;
}

.table-head--dark .table-head-item:nth-child(8) span {
  background-color: #708EC9;
}

.table-head--dark .table-head-item:nth-child(10) span {
  background-color: #8869BA;
}

.table-head-mobile .table-head-item span {
  display: none;
}

.table-row--primary {
  position: relative;
  width: 98%;
  height: 70px;
  margin-bottom: 10px;
  margin-left: 5px;
  padding: 3px;
  font-size: 11px;
  background-color: var(--darker-grey);
  border-radius: 30px;
  box-shadow: 0px 2px 10px 0px #00000040;
  list-style-type: none;
}

.table-row--primary-mobile {
  width: 93%;
  height: 50px;
  margin-left: 8px;
}

.expanded-rows-wrapper {
  width: 98%;
  margin-bottom: 10px;
  margin-left: 8px;
  font-size: 11px;
  background-color: #ffffff;
  border-radius: 30px;
}

.table-row-list--primary {
  display: flex;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.table-row-list--primary .table-row-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 64px;
  padding-top: 6px;
  background-color: #ffffff;
  overflow-x: auto;
}

.table-row--primary .table-row-item-mobile {
  height: 45px;
}

.table-row-list--primary .table-row-item:first-child {
  width: 2%;
}

.table-row-list--primary .table-row-item:nth-child(2) {
  width: 10%;
}

.table-row-list--primary .table-row-item:nth-child(3) {
  width: 6%;
}

.table-row-list--primary .table-row-item:nth-child(4) {
  width: 10%;
}

.table-row-list--primary .table-row-item:nth-child(5) {
  width: 7%;
}

.table-row-list--primary .table-row-item:nth-child(6) {
  width: 7%;
}

.table-row-list--primary .table-row-item:nth-child(7) {
  width: 5%;
}

.table-row-list--primary .table-row-item:nth-child(8) {
  width: 6%;
}

.table-row-list--primary .table-row-item:nth-child(9){
  width: 9%;
  background-color: #FFEAA1;
}

.table-row-list--primary .table-row-item:nth-child(10) {
  width: 13%;
}

.table-row-list--primary .table-row-item:nth-child(11){
 width: 10%;
}

.table-row-list--primary .table-row-item:nth-child(12){
  width: 7.5%;
 }

 .table-row-list--primary .table-row-item:nth-child(13){
  width: 3.5%;
 }

.table-row-list--primary .table-row-item:nth-child(14){
  width: 4%;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.table-row-list--primary .table-row-item-mobile:first-child {
  width: 0;
}

.table-row-list--primary .table-row-item-mobile:nth-child(2) {
  width: 40%;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.table-row-list--primary .table-row-item-mobile:nth-child(2)::after {
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  width: 2px;
  height: 70px;
  background-color: var(--darker-grey);
}

.table-row-list--primary .table-row-item-mobile:nth-child(3) {
  width: 50%;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.table-row-list--primary .table-row-item-mobile:nth-child(4),
.table-row-list--primary .table-row-item-mobile:nth-child(5),
.table-row-list--primary .table-row-item-mobile:nth-child(6),
.table-row-list--primary .table-row-item-mobile:nth-child(7),
.table-row-list--primary .table-row-item-mobile:nth-child(8),
.table-row-list--primary .table-row-item-mobile:nth-child(9),
.table-row-list--primary .table-row-item-mobile:nth-child(10),
.table-row-list--primary .table-row-item-mobile:nth-child(11),
.table-row-list--primary .table-row-item-mobile:nth-child(12),
.table-row-list--primary .table-row-item-mobile:nth-child(13) {
  width: 50%;
}

.table-row-list--primary .table-row-item-mobile:nth-child(13) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.table-row-list--primary .table-row-item-mobile:nth-child(14){
  width: 20%;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.table-row-list--primary .table-row-item:not(:last-child)::before {
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  width: 2px;
  height: 70px;
  background-color: var(--darker-grey);
}

.table-row-list--primary .table-row-item:first-child::before,
.table-row-list--primary .table-row-item:nth-child(2)::before,
.table-row-list--primary .table-row-item:nth-child(11)::before,
.table-row-list--primary .table-row-item:nth-child(12)::before,
.table-row-list--primary .table-row-item:nth-child(13)::before,
.table-row-list--primary .table-row-item:nth-child(14)::before,
.table-row-list--primary .table-row-item-mobile:nth-child(3)::before,
.table-row-list--primary .table-row-item-mobile:nth-child(4)::before,
.table-row-list--primary .table-row-item-mobile:nth-child(5)::before,
.table-row-list--primary .table-row-item-mobile:nth-child(6)::before,
.table-row-list--primary .table-row-item-mobile:nth-child(7)::before,
.table-row-list--primary .table-row-item-mobile:nth-child(8)::before{
  display: none;
}

.table-row-item__button--user {
  font-size: 11px;
  padding: .6rem;
  border-radius: 10px;
  transition: background-color 0.1s ease-out;
} 

.table-row-item__button--user:focus-visible {
  outline: none;
  border: none;
} 

.table-row-item__button--user:hover {
  background-color:  var(--darker-grey);
}

.table-row--primary-mobile .table-row-item__button--user {
  font-size: 9px;
}

.table-body-cell {
  padding-top: 15px;
  vertical-align: middle;
  font-size: 11px;
  white-space: wrap;
}

.table-body-cell.not-expanded {
  border-bottom: 1px solid #000000;
}

.table-body-row:first-child .table-body-cell:first-child {
  padding: 0;
  border-bottom: 1px solid #000000;
}

.table-body-row:first-child  .table-body-cell:nth-child(2) {
  padding: 0;
  text-align: left;
  border-bottom: 1px solid #000000;
}

.table-body-row:first-child  .table-body-cell:nth-child(3) {
  padding: 0;
  border-right: 1px solid #000000;
  border-bottom: 1px solid #000000;
}

.table-body-row:first-child  .table-body-cell:nth-child(8) {
  padding: 0 5px;
  background-color: rgba(117, 88, 55, 0.1);
}

.table-body-row:first-child  .table-body-cell:nth-child(10) {
  padding: 0;
  border-left: 1px solid #000000;
}

.table-body-row:first-child  .table-body-cell:nth-child(11) {
  padding: 0;
}

.table-body-row:first-child  .table-body-cell:nth-child(12) {
  padding: 0;
  border-bottom: 1px solid #000000;
}

.table-body-row:not(:first-child):not(:last-child) td:nth-child(7){
  border-left: 1px solid #000000;
}

.table-body-row:not(:first-child):not(:last-child) .table-body-cell {
  padding-top: 5px 0;

}

.table-body-row:last-child .table-body-cell,
.table-body-row-expanded:last-child .table-body-cell {
  height: 36px;
  margin-top: 5px;
  padding: 0;
  border-bottom: 1px solid #000000;
}

.table-body-row-expanded:last-child .table-body-cell:nth-child(7){
  border-left: 1px solid #000000;
}

.deco-line-table {
  width: 90%;
  height: 1px;
  margin: 0 auto;
  margin-top: 12px;
  background-color: rgba(102, 101, 100, 0.7);
}

.goto-faq-btn {
  position: absolute;
  background-color: var(--main-light-color);
  width: 42px;
  height: 42px;
  border-radius: 50%;
  padding: 0;
  font-size: 22px;
  font-weight: 500;
  vertical-align: middle;
  text-align: center;
}

.button-races {
  width: 90px;
  height: 36px;
  font-size: 11px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: right;
  background-size: 30%;
  border: none;
  cursor: pointer;
  text-align: left;
}

.button-races-opened {
  background-image: url('./Images/arrow_up.svg');
}

.button-races-closed {
  background-image: url('./Images/arrow_down.svg');
}

.vin-pop-up-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.vin-pop-up {
  margin-top: 5px ;
  margin-bottom: 5px;
  /* padding: 5px 3px 0 3x; */
  font-size: 11px;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: top;
  background-size: 30px;
  vertical-align: bottom;
  border: none;
  border-radius: 10px;
  transition: background-color 0.1s ease-out;
}

.table-row-item-mobile .vin-pop-up {
  height: 40px;
  margin: 0;
  background-size: 25px;
}

.vin-pop-up:hover {
  background-color:  var(--darker-grey);
}

.vin-pop-up p {
  width: 40px;
  margin: 0;
  padding-top: 20px;
  text-align: center;
}

.table-row-item-mobile .vin-pop-up p {
  padding-top: 20px;
  margin:0;
  text-align: center;
}

/*MAIN TABLE MOBILE*/

.table-head-mobile {
  position: relative;
}

.table-head-cell-mobile {
  padding: 15px;
  background-color: rgba(217, 217, 217, 0.2);
  border-bottom: 1px solid #000000;
}

.table-head-cell-mobile:nth-child(2) {
  width: 30%;
  text-align: left;
  background-color: rgba(217, 217, 217, 0.6);
}

.table-head-cell-mobile:nth-child(3),
.table-head-cell-mobile:nth-child(4),
.table-head-cell-mobile:nth-child(5),
.table-head-cell-mobile:nth-child(6),
.table-head-cell-mobile:nth-child(7),
.table-head-cell-mobile:nth-child(8),
.table-head-cell-mobile:nth-child(9) {
  width: 30%;
  padding-left: 15px;
  text-align: left;
}

.table-head-cell-mobile:last-child {
  width: 40%;
  padding: 0 3%;
}

.table-body-cell-mobile {
  padding: 15px;
  background-color: rgba(217, 217, 217, 0.2);
  border-bottom: 1px solid #000000;
}

.table-body-cell-mobile:nth-child(3),
.table-body-cell-mobile:nth-child(4),
.table-body-cell-mobile:nth-child(5),
.table-body-cell-mobile:nth-child(6),
.table-body-cell-mobile:nth-child(8),
.table-body-cell-mobile:nth-child(9) {
  width: 30%;
  height: 70px;
  text-align: left;
}

.table-body-cell-mobile:nth-child(2) {
  position: relative;
  text-align: left;
  background-color: rgba(217, 217, 217, 0.6);
}

.table-body-cell-mobile:nth-child(7) {
  max-width: 20px;
  height: 70px;
  text-align: left;
  word-wrap: break-word;
}

.table-body-cell-mobile:nth-child(3) {
  padding-left: 20px;
  text-align: left;
}

.button-show-map{
  min-width: 35px;
  min-height: 35px;
  background-color: transparent;
  background-image: url("./Images/route.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
  border: none;
  transform: rotate(45deg);
}

.button-show-graph{
  min-width: 50px;
  min-height: 50px;
  background-color: transparent;
  background-image: url("./Images/electric-bolt.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70%;
  border: none;
}

.shift-info-item .button-show-map,
.shift-info-item .button-show-graph {
  min-width: 40px;
  min-height: 40px;
  margin-left: 30px;
}

.button-show-shift-info {
  width: 30px;
  height: 30px;
  background-color: var(--main-dark-color);
  background-image: url("./Images/arrow-open-list.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
  border-radius: 50%;
  box-shadow: 0px 4px 4px 0px #00000040;
}


.button-warning-mobile {
  position: absolute;
  top:0;
  left:0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: rgba(228, 127, 127, 0.3);
  border: none;
}

.back-forth-buttons {
  display: flex;
  justify-content: flex-end;
}

.button-forth {
  margin-left: 10px;
  padding: 20px 20px;
  background-color: rgba(250, 213, 110, 0.5);
  background-image: url('./Images/pagination-next.svg');
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  border-radius: 10px;
}

.button-back {
  padding: 20px 20px;
  background-color: rgba(250, 213, 110, 0.5);
  background-image: url('./Images/pagination-prev.svg');
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  border-radius: 10px;
}

.button-info-wrapper {
  display: flex;
  justify-content: flex-end;
  padding-right: 5%;
}

.button-more-info {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: var(--main-dark-color) url("./Images/arrow-open-list.svg") no-repeat center;
  background-size: 45%;
  box-shadow: 0px 4px 4px 0px #00000040;
}


/*OVERLAY SHIFT MOBILE*/

.overlay-shift {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100%;
  font-size: 16px;
  background-color: rgba(102, 101, 100, 0.7);
}

.info-container-wrapper {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 400px;
  height: 85%;
  padding-top: 3%;
  padding-bottom: 2%;
  background-color: #f2f2f2;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: auto;
}

.info-container-wrapper::-webkit-scrollbar {
  display: none;
}

.button-name-wrapper {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
}

.button-name-wrapper > p {
  width: 80%;
  margin: 0;
  margin-bottom: 4%;
  padding-left: 5%;
  padding-top: 6%;
}

.close-button-wrapper {
  padding-right: 3%;
}

.close-shift-overlay {
  padding: 20px;
  background-color: transparent;
  background-image: url('./Images/cross.svg');
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.deco-line {
  width: 90%;
  height: 1px;
  margin: 0 auto;
  margin-bottom: 7%;
  background-color: #000000;
}

.shift-info-list {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 2%;
  padding: 0;
  list-style: none;
}

.shift-info-item {
  width: 50%;
  margin-bottom: 10%;
}

.shift-info-item:last-child {
  flex: 2;
}

.shift-info-item:last-child p {
  flex: 2;
  padding-left: 10%;
}

.shift-info-item p {
  margin: 0;
  padding-left: 20%;
}

.shift-info-item p:first-child {
  margin-bottom: 1%;
  color: rgba(0, 0, 0, 0.5);
}

.shift-info-item:has(.button-show-graph),
.shift-info-item:has(.button-show-map){
  margin-bottom: 2%;
}

.shift-images {
  width:79%;
  margin: 0 auto;
  margin-bottom: 7%;
}

.button-shift-images {
  width: 75px;
  padding: 0;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: right;
  background-size: 30%;
  border: none;
  cursor: pointer;
}

.button-shift-images p {
  margin: 0;
  padding: 0;
  padding-right: 10px;
  vertical-align: middle;
}

.shift-images-open {
  background-image: url('./Images/arrow_down.svg');
}

.shift-images-close {
  background-image: url('./Images/arrow_up.svg');
}

.shift-images-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0;
  list-style: none;
}

.shift-images-item {
  width: 50%;
  text-align: center;
}

.shift-images-button{
  background-color: transparent;
  border: none;
}

.shift-images-button img {
  border-radius: 5px;
  object-fit: cover;
}

.shift-images-button .fill-image {
  border-radius: 5px;
  object-fit: contain;
}

.trip-images-list{
  margin-right: 10px;
}

.trip-images-list:last-child {
  margin-right: 0;
}

.info-trips > p {
  width: 67%;
  margin: 0 auto;
  margin-bottom: 3%;
  color: rgba(0, 0, 0, 0.5);
}


.open-trip-button-wrapper {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 25px;
  background-color: #D4D2D6;
  border-radius: 10px;
}

.open-trip-button {
  width: 100%;
  margin: 0 auto;
  padding: 15px 0 15px 15%;
  font-size: 16px;
  text-align: left;
  color: rgba(0, 0, 0, 0.5);
  background-color: transparent;
  background-image: url('./Images/arrow_down.svg');
  background-repeat: no-repeat;
  background-position: 84%;
  background-size: 10%;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.trips-list {
  width: 85%;
  margin: 0 auto;
  padding: 0;
  list-style: none;
}

.trip-info-list {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 15px;
  padding: 0 10%;
  padding-bottom: 10%;
  background-color: #A3A2A4;
  list-style: none;
  border-radius: 10px;
}

.trip-info-item {
  width: 100%;
  margin-bottom: 10%;
}

.trip-info-item:first-child {
  margin-bottom: 0;
}

.trip-info-item:nth-child(3),
.trip-info-item:nth-child(4) {
  width: 50%;
}

.trip-info-item:nth-child(4){
  text-align: right;
}

.trip-info-item p {
  margin: 0;
}

.trip-info-item p:first-child {
  margin-bottom: 1%;
  color: rgba(0, 0, 0, 0.5);
}

.close-trip-button-wrapper {
  display: flex;
  justify-content: flex-end;
  padding-top: 10px;
}

.close-trip-button {
  width: 100%;
  padding: 20px;
  background-color: transparent;
  background-image: url('./Images/arrow_up.svg');
  background-repeat: no-repeat;
  background-position: 93%;
  background-size: 12%;
  border: none;
}

/*OVERLAY VIN MOBILE*/

.vin-info-wrapper {
  height: 45%;;
}

.car-image{
  padding-left: 10%;
}

.vin-list {
  display: flex;
  flex-wrap: wrap;
  width: 75%;
  margin: 0 auto;
  margin-bottom: 2%;
  padding: 0;
  list-style: none;
}

.vin-item {
  width: 100%;
  margin-bottom: 10%;
}

.vin-item:nth-child(2) {
  width: 60%;
}
.vin-item:nth-child(3) {
  width: 40%;
}

.vin-item:nth-child(3) {
  text-align: end;
}

.vin-item p {
  margin: 0;
}

.vin-item p:first-child {
  margin-bottom: 1%;
  color: rgba(0, 0, 0, 0.5);
}

/*OVERLAY WARNING MOBILE*/

.warning-info-wrapper {
  height: 45%;
  background-color: #F7D9D9;
}

.warning-icon {
  padding-top: 5%;
  padding-left: 5%;
}

.warning-head-wrapper p {
  padding-top: 5%;
}

.warning-list {
  display: flex;
  flex-wrap: wrap;
  width: 75%;
  margin: 0 auto;
  margin-bottom: 2%;
  padding: 0;
  list-style: none;
}

.warning-item {
  width: 100%;
  margin-bottom: 10%;
}

.warning-item p {
  margin: 0;
}

.warning-item p:first-child {
  margin-bottom: 1%;
  color: rgba(0, 0, 0, 0.5);
}

/*OVERLAY DOWNLOAD MOBILE*/

.overlay-shift-yellow {
  padding-top: 30px;
  background-color: #FCD46E;
}

.close-download-overlay {
  background-color: #FCBA03;
}

.close-download-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-bottom: 80px;
  padding-right: 10%;
}

.download-section {
  width: 60%;
  margin: 0 auto;
}

.download-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.download-button {
  font-size: 20px;
  background-color: transparent;
  border: none;
}

/*GALLERY POP UP*/

.button-gallery {
  background-color: transparent;
  border: none;
}

.button-gallery img {
  border-radius: 5px;
  object-fit: cover;
}

.button-gallery .fill-image {
  border-radius: 5px;
  object-fit: contain;
}

.overlay-gallery-mobile {
  position: fixed;
  z-index: 6;
  width: 100%;
  height: 100%;
  font-size: 16px;
}

.overlay-darker {
  backdrop-filter: blur(1px);
  overflow-y: auto;
}

.gallery-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 800px;
  height: 600px;
  margin: 5% auto;
  padding: 45px;
  background-color: var(--main-dark-color);
  border-radius: 15px;
}

.gallery-mobile-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80%;
  margin: 20% auto;
}

.close-gallery-button{
  align-self: flex-end;
  padding: 30px 30px;
  background-color: #FBF3DA;
  background-image: url('./Images/cross.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
  border: none;
  border-radius: 40px;
}

.close-gallery-button-mobile {
  margin-right: 10%;
  margin-left: auto;
  padding: 20px 20px;
  background-color: #FBF3DA;
  background-image: url('./Images/cross.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
  border: none;
  border-radius: 40px;
}

.active-image {
  align-self: center;
  border-radius: 15px;
}

.gallery-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 2%;
  margin: 50px 0;
  list-style: none;
}

.gallery-item {
  position: relative;
  margin-top: 10px;
}

.gallery-item img {
  border-radius: 10px;
}

.gallery-desktop-button-back {
  margin-top: 10px;
  margin-left: 6px;
  padding: 20px 20px;
  background-color:#FBF3DA;
  background-image: url('./Images/arrow-left.svg');
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  border-radius: 20px;
}

.gallery-desktop-button-forth {
  margin-top: 10px;
  margin-left: 6px;
  padding: 20px 20px;
  background-color:#FBF3DA;
  background-image: url('./Images/arrow-right.svg');
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  border-radius: 20px;
}

.gallery-item img {
  display: block;
}

.gallery-item a:not(.active)::before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  content: '';
  background-color: rgba(33, 27, 39, 0.75);
  border-radius: 10px;
}

.navigation-buttons-gallery {
  display: flex;
  justify-content: space-between;
  margin: 0 20%;
}

.gallery-mobile-button-back {
  margin-top: 10px;
  margin-left: 6px;
  padding: 20px 20px;
  background-color:#FBF3DA;
  background-image: url('./Images/arrow-left.svg');
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  border-radius: 20px;
}

.gallery-mobile-button-forth {
  margin-top: 10px;
  margin-left: 6px;
  padding: 20px 20px;
  background-color:#FBF3DA;
  background-image: url('./Images/arrow-right.svg');
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  border-radius: 20px;
}


/*VIN POP UP*/

.vin-pop-up-desktop {
  z-index: 500;
  width: 500px;
  padding-top: 20px;
  background-color: #FDF9ED;
  border: 6px solid #FBF3DA;
  border-radius: 10px;
}

.vin-desktop-list {
  display: flex;
  flex-wrap: wrap;
  width: 65%;
  margin: 0 auto;
  padding: 0;
  list-style: none;
}

.vin-desktop-item {
  width: 100%;
  margin-bottom: 10%;
}

.vin-desktop-item:first-child {
  margin-bottom: 5%;
}

.vin-desktop-item:nth-child(2) {
  width: 60%;
}

.vin-desktop-item:nth-child(3) {
  width: 40%;
  text-align: end;
}

.vin-desktop-item:nth-child(4) {
  width:40%;
}

.vin-desktop-item:nth-child(5) {
  width:60%;
  text-align: end;
}

.vin-desktop-item p {
  margin: 0;
}

.vin-desktop-item p:first-child {
  margin-bottom: 1%;
  color: rgba(0, 0, 0, 0.5);
}

.vin-images-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-items: center;
  width: 100%;
  margin: 0;
  margin-top: 10px;
  padding: 0;
  list-style-type: none;
}

.vin-images-button {
  padding: 0;
  background-color: transparent;
  border: none;
}

/*WARNING POP UP*/

.button-warning-desktop {
  background-color: transparent;
  border: none;
}

.warning-pop-up-desktop {
  max-width: 500px;
  padding-top: 30px;
  background-color: #F7D9D9;
  border: 6px solid #F1BFBF;
  border-radius: 10px;
}

.warning-desktop-list {
  display: flex;
  flex-wrap: wrap;
  width: 75%;
  margin:0 auto;
  padding:0;
  list-style: none;
}

.warning-desktop-item {
  width: 100%;
  margin-bottom: 10%;
}

.warning-desktop-item:first-child {
  width: 60%;
  margin-bottom: 0;
}

.warning-desktop-item:nth-child(2) {
  width: 40%;
  margin-bottom: 0;
  text-align: end;
}

.warning-desktop-item p {
  margin: 0;
}

.warning-desktop-item p:first-child {
  margin-bottom: 1%;
  color: rgba(0, 0, 0, 0.5);
}

/*PAGINATION*/

.pagination {
  display: flex;
  flex-wrap: wrap;
  vertical-align: middle;
  margin-left: auto;
  /* margin-bottom: 100px; */
}

.pagination span {
  align-self: flex-end;
}

.pagination-button {
  margin: 10px 5px 0 5px;
  padding: 10px 15px;
  background-color: #F0E4BB;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 4px 10px 0px #00000040;

}

.pagination-button-mobile {
  margin: 0 3px 0 3px;
  padding: 5px 8px;
  background-color: #F0E4BB;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 4px 10px 0px #00000040;
}

.active-page {
  background-color: #F0D07E;
}

.pagination-back-forth-buttons {
  margin: 10px 5px 0 5px;
  padding: 5px 15px 0 15px;
  background-color: transparent;
  background-size: 100%;
  border-radius:10px;
}

.pagination-mobile-button-forth {
  width: 25px;
  height: 20px;
  margin-left: 6px;
  background-color:transparent;
  background-image: url('./Images/pagination-next.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  border: none;
  border-radius: 10px;
}

.pagination-mobile-button-back {
  width: 25px;
  height: 20px;
  margin-right: 6px;
  background-color:transparent;
  background-image: url('./Images/pagination-prev.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  border: none;
  border-radius: 10px;
}

/*LOGIN FORM*/

.login-wrapper {
  display: flex;
  width: 100%;
  height: 100vh;
  margin: auto;
  background-color: var(--main-dark-color);
}

.form-wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* width: 100%;
  height: 100vh;
  margin: 0 auto;
  background-color: var(--main-dark-color); */
  width: 50%;
  margin: auto;
  padding: 65px 40px;
  border: 8px solid #F0D07E;
  border-radius: 20px;
}

.form-wrapper__logo {
  width: 200px;
  height: auto;
}

.form-wrapper h1 {
  margin: 0;
  margin-bottom: 20px;
  font-size: 50px;
}

.login-form{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 300px;
  margin: 0 auto;
  /* padding: 20px;
  border: 3px solid #F0D07E;
  border-radius: 20px; */
}

.login-form-desktop{
  min-width: 60%;
}

.login-form-mobile {
  min-width: 80%;
}

.login-form input {
  padding: 0 20px;
  border: 1px solid rgba(102, 101, 100, 0.7);
  border-radius: 20px;
}

.login-form-mobile .input-validated {
  margin-bottom: 10%;
}

.login-form-mobile .input-error {
  margin-bottom: 1%;
}

.login-form-desktop .input-validated {
  margin-bottom: 5%;
}

.login-form-desktop .input-error {
  margin-bottom: 1%;
}

.login-form input:first-child {
  margin-top: 50px;
}

.login-form input:focus {
  outline-color: #666564;
}

.login-form-desktop input {
  width: 100%;
  height: 50px;
}

.login-form-mobile input {
  width: 90%;
  height: 50px;
}

.login-form button {
  background-color: #FCD46E;
  border: none;
  border-radius: 30px;
}

.login-form-desktop button {
  width: 40%;
  height: 40px;
  margin-bottom: 3%;
}

.login-form-mobile button {
  width: 60%;
  height: 40px;
  margin-bottom: 5%;
}

.error-invisible {
  visibility: hidden;
}

.error-visible{
  color: #E47F7F;
}

.login-form-mobile .error-message {
  align-self: flex-start;
  margin: 0 0 5% 5%;
  font-size: 9.5px;
  color: #E47F7F;
}

.login-form-desktop .error-message {
  align-self: flex-start;
  margin: 0 0 5% 20%;
  font-size: 9.5px;
  color: #E47F7F;
}


/*ADMIN MENU*/

.admin-menu {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  padding: 100px 0;
}

.admin-menu-list {
  display: grid;
  grid-template-columns: 50% 50%;
  row-gap: 80px;
  justify-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.admin-menu-list-mobile {
  display: grid;
  grid-template-columns: 100%;
  row-gap: 50px;
  justify-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.admin-menu-list .admin-menu-item{
  position: relative;
  width: 90%;
  min-height: 400px;
  background-color: rgba(217, 217, 217, 0.5);
  border: 1px solid #000000;
  border-radius: 20px;
}

.admin-menu-list-mobile .admin-menu-item {
  position: relative;
  width: 90%;
  height: 200px;
  background-color: rgba(217, 217, 217, 0.5);
  border: 1px solid #000000;
  border-radius: 20px;
}

.linked-to{
  position: absolute;
  z-index: 2;
  display: block;
  align-self: baseline;
  width: 100%;
  margin: 0 auto;
  padding-top: 300px;
  padding-bottom: 100px;
  font-size: 15px;
  text-align: center;
  color: #000000;
  text-decoration: none;
}

.admin-menu-list-mobile .linked-to{
  position: absolute;
  z-index: 2;
  display: block;
  align-self: baseline;
  width: 100%;
  margin: 0 auto;
  padding-top: 150px;
  padding-bottom: 50px;
  font-size: 15px;
  text-align: center;
  color: #000000;
  text-decoration: none;
}

.admin-menu-item{
  transition: transform 0.5s ease-in-out, background-color 0.5s ease-in-out, border-color 0.5s ease-in-out;
}

.admin-menu-item:hover{
  background-color: rgba(217, 217, 217, 1);
  border-color: #FCBA03;
  transform: scale(1.05);
}

.admin-menu-list .admin-menu-item::before{
  position: absolute;
  top: 20%;
  left: 50%;
  width: 150px;
  height: 150px;
  margin-left: -75px;
  content:"";
  background-repeat: no-repeat;
  background-size: 150px;
}

.admin-menu-list-mobile .admin-menu-item::before{
  position: absolute;
  top: 20%;
  left: 50%;
  width: 100px;
  height: 100px;
  margin-left: -50px;
  content:"";
  background-repeat: no-repeat;
  background-size: 100px;
}

.admin-menu-table::before{
  background-image: url("./Images/table.svg");
}

.admin-menu-tasks::before{
  background-image: url("./Images/tasks.svg");
}

.admin-menu-service::before{
  background-image: url("./Images/service.svg");
}
/*MAP POPUP*/

.modal-container{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  display: flex;
  width: 100%;
  height: 100%;
}

.modal{
  position: relative;
  width: 80%;
  min-height: 80%;
  margin: auto;
}

.modal-button{
  position: relative;
  z-index: 6;
  margin-left:100%;
  margin-bottom: 20px;
  padding: 30px 30px;
  background-color: #FBF3DA;
  background-image: url('./Images/cross.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
  border: none;
  border-radius: 10px;
}

.mobile-modal-button{
  margin-left: 85%;
}

.leaflet-container{
  width: 100%;
  height: 80vh;
  border-radius: 20px;
}

.leaflet-control-attribution {
  display: none;
}


/*CHART*/

.chart-modal{
  position: relative;
  margin: auto;
}

.mixed-chart{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
  min-width: 1000px;
}

.mixed-chart-mobile{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-x: auto;
  overflow-y: hidden;
}

/*TASK TRACKER*/

.tracker-waiting{
  margin: auto;
  font-size: 50px;
}

/*ADD TASK FORM*/

.dark-modal-container{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  background-color: rgba(55, 55, 55, 0.95);
}

.modal-form {
  position: relative;
  margin: auto;
  padding: 110px 140px;
  width: 770px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 15px;
}

.modal-close-transparent{
  position: absolute;
  top: 20px;
  left: 900px;
  z-index: 3;
  width: 80px;
  height: 80px;
  background-image: url("./Images/close-cross-white.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
}

.add-task-form {
  display: grid;
  grid-template-columns: 151px 46px 151px 77px 348px;
  grid-template-rows: repeat(5, 52px);
  row-gap: 51px;
}

.field-group {
  position: relative;
  margin: 0;
}

.add-task-form .field-group-worker,
.add-task-form .field-group-area,
.add-task-form .field-group-machinery {
  grid-column: 1 / 4;
}

.add-task-form .field-group-start-date {
  grid-column: 1 / 2;
}

.add-task-form .field-group-end-date {
  grid-column: 3 / 4;
}

/* .add-task-form .field-group-task-type,
.add-task-form .field-group-task,
.add-task-form .field-group-description,
.add-task-form .add-button {
  grid-column: 5 / -1;
} */

.add-task-form .field-group-description {
  grid-row: 3 / 5;
}

.add-task-form label,
.label {
  position: absolute;
  top: -25px;
  color: rgba(0, 0, 0, 0.5);
}

.field-group .select,
.field-group .input,
.field-group textarea {
  padding: 0 60px 0 20px;
  border: none;
  border-radius: 10px;
  outline: 1px solid #000000;
}

.field-group .select,
.field-group .input {
  min-height: 52px;
}

.field-group textarea {
  width: 400px;
  height: 203px;
  margin: 0;
  padding: 0;
}

.field-group-select .select{
  margin: 0;
  width: 348px;
}

.field-group-input .input {
  width: 111px;
  margin: 0;
  padding: 0 20px;
}

.add-button {
  background-color: rgba(250, 213, 110, 0.5);
  border-radius: 10px;
}


/*Service*/


