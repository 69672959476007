:root{
  --main-dark-color: #211B27;
}

.gantt-chart-container {
  position: relative;
  width: 1500px;
  margin-left: 20px;
}

.gantt-chart-container-mobile {
  position: relative;
  width: 97vw;
  margin-left: 20px;
}

.list-chart-wrapper {
  display: flex;
  width: 1500px;
}

.gantt-chart-container-mobile .list-chart-wrapper {
  width: 100vw;
}

.chart-wrapper{
  flex-grow: 1;
  height: 90vh;
  margin: auto;
  margin-right: 50px;
  border: 12px solid var(--main-dark-color);
  border-radius: 30px;
  overflow: scroll;
}

.gantt-chart-container-mobile .chart-wrapper {
  height: 80vh;
  border: 5px solid var(--main-dark-color);
  border-radius: 15px;
}

.chart-wrapper::-webkit-scrollbar-thumb {
  width: 10px;
  background: var(--main-dark-color);
  border-radius: 15px;
  border: 5px solid transparent;
  background-clip: padding-box;
}

.chart-wrapper::-webkit-scrollbar {
  width: 23px;
  background: #d9d9d9;
  border-radius: 10px;
}

.chart-wrapper::-webkit-scrollbar-corner {
  border-radius: 30px;
}

.chart-values{
  position: relative;
  display: flex;
  margin: 0 0 30px 0;
  padding: 0;
  list-style-type: none;
}

.chart-values li{
  flex: 1;
  min-width: 100px;
  padding-top: 20px;
  font-size: 15px;
  text-align: center;
}

.chart-values li:not(:last-child){
  position: relative;
}

.chart-values li:not(:last-child)::before{
  position: absolute;
  right: 0;
  border-right: 3px solid #CDCCCE;
  content: "";
}

.chart-bars{
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.chart-bars li{
  position: relative;
  color: #000000;
  margin-bottom: 15px;
  font-size: 13px;
  border-radius: 20px;
  padding: 10px 20px;
  width: 0;
  height: 35px;
  box-shadow: 0px 4px 4px 0px #00000040;

}

.chart-bars section {
  position: relative;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chart-bars p {
  margin: 0;
}

.chart-bars .button-info-task {
  position: relative;
  width: 30px;
  height: 30px;
  background-color: var(--main-dark-color);
  box-shadow: 0px 5px 10px 0px #00000040;
  border-radius: 50%;
}

.chart-bars .button-info-task::after,
.chart-bars .button-info-task::before {
  position: absolute;
  width: 3px;
  left: 15px;
  transform: translateX(-50%);
  background-color: #ffffff;
  content: "";
}

.chart-bars .button-info-task::after {
  height: 3px;
  top: 7px;
  border-radius: 50%;
}

.chart-bars .button-info-task::before {
  height: 10px;
  top: 13px;
  border-radius: 30px;
}

.button-close-gantt {
  position: absolute;
  top: 65px;
  left: 1315px;
  z-index: 4;
  display: block;
  width: 55px;
  height: 55px;
  margin-bottom: 20px;
  margin-left: 20px;
  background-color: #211B27;
  box-shadow: 0px 5px 10px 0px #00000040;
  background-image: url('../../Images/close-cross-white.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
  border-radius: 50%;
}

.button-add-task {
  position: absolute;
  top: 130px;
  left: 40px;
  z-index: 4;
  min-width: 70px;
  width: 70px;
  height: 60vh;
  margin-left: 20px;
  font-size: 30px;
  color: #ffffff;
  background-color: #211b27;
  border-radius: 40px;
  box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.25);
  transition: width 1s ease;
}

.button-add-task.open {
  width: 1100px;
  height: 650px;
  background-color: rgba(33, 27, 39, 1);
  color: rgba(33, 27, 39, 1);
}

.task-progress-bar {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
  height: 100%;
  border-radius: 20px;
  transition: width 0.3s ease;
}

.task-progress-bar p,
.task-progress-bar-approved p {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: none;
}

.task-progress-bar p {
  color: rgba(33, 27, 39, 0.749);
}

.chart-bars li:hover .task-progress-bar p,
.chart-bars li:hover .task-progress-bar-approved p {
  display: block;
}

.chart-bars li:hover {
  outline: 3px solid #211B2780;
}

.task-progress-bar-approved {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  height: 100%;
  border-radius: 20px;
  transition: width 0.3s ease;
}
