.task-list {

  &__item {
    position: relative;
    display: flex;
    gap: 5px;
    width: 95%;
    margin: 20px;
    margin-top: 50px;
    margin-left: 30px;
  }

  &__item-additional-info {
    margin-left: 15%;
  }

  // &__item:first-child {
  //   margin-top: 40px;
  // }

  &__item:first-child::before {
    position: absolute;
    top: -20px;
    left: -30px;
    width: 105.4%;
    height: 2px;
    background-color: #bebebe
  }

  &__item::after {
    position: absolute;
    bottom: -20px;
    left: -30px;
    width: 105.4%;
    height: 2px;
    background-color: #bebebe;
    content: "";
  }

  &__item-additional-info::after {
    left: -15.9%;
  }

  &__item p {
    width: 80px;
    padding-right: 25px;
    font-size: 1.1rem;
    word-wrap: break-word;
  }
}
