:root {
  --main-dark-color: #211b27;
  --main-light-color: #f0d07e;
  --liht-grey: #eeeeee;
  --darker-grey: #d4d2d6;
}

.switcher-wrapper {
  display: inline-flex;
  justify-content: center;
  /* min-width: 200px; */
  margin-right: 2rem;
  padding: 0.6rem 1rem;
  font-size: 1.2rem;
  background-color: var(--liht-grey);
  border-radius: 10px;
}

.switcher-wrapper-mobile {
  margin-right: 0;
  padding: 0.4rem 0.7rem;
  font-size: 1rem;
}

.link-switch {
  display: block;
  min-width: 75px;
  padding: 10px 24px;
  color: #000000;
  background-color: var(--main-light-color);
  text-decoration: none;
  text-align: center;
}

.switcher-wrapper-mobile .link-switch {
  padding: 6px 16px;
}

.link-switch-current {
  color: #eeeeee;
  background-color: var(--main-dark-color);
}

.link-switch-users {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.link-switch-equip {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
