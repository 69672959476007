/*NAVIGATION BLOCK*/

/* .logo-monstr {
  display: block;
  width: 50px;
  height: 90px;
  margin-bottom: 4rem;
  text-align: center;
} */

.navigation-container {
  box-sizing: border-box;
  margin-left: 1vw;
  margin-right: 1.5vw;
  margin-top: auto;
  margin-bottom: auto;
  width: 80px;
  height: 95vh;
  max-height: 1200px;
  padding: 1rem;
  background-color: var(--main-dark-color);
  border-radius: 20px;
  box-shadow: 0 7px 10px var(--main-dark-color-shadow);
}

.navigation-container-mobile {
  position: fixed;
  z-index: 10;
  bottom: 20px;
  width: 90%;
  height: 50px;
  margin-top: 0;
  margin-bottom: 0;
  padding: 1rem 25px;
}

.navigation {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  align-items: center;
}

.navigation-container-mobile .navigation {
  flex-direction: row;
}

.navigation-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  margin-top: 5vh;
  padding: 0;
  list-style-type: none;
  z-index: 2;
}

.navigation-container-mobile .navigation-list {
  flex-direction: row;
  margin-top: 0;
  margin-left: auto;
}

.navigation-item {
  width: 30px;
  height: 30px;
  padding: 0.6rem;
  margin-bottom: 0.5rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px;
}

.navigation-container-mobile .navigation-item {
  width: 20px;
  height: 20px;
  margin-bottom: 0;
  background-size: 60%;
}

.navigation-item:last-child {
  margin-bottom: 0;
  margin-top: 1rem;
}

.navigation-container-mobile .navigation-item:last-child {
  margin-top: 0;
}


.navigation-item.current-item {
  background-color: #eeeeee;
  border-radius: 15px;
}

.navigation-item-link {
  display: block;
  width: 100%;
  height: 100%;
}

.button-logout2 {
  width: 50px;
  height: 37px;
  margin-top: auto;
  margin-bottom: 1vh;
  background-image: url("../Images/Menu/logout-icon.svg");
  background-repeat: no-repeat;
  background-position: 65%;
  background-size: 50%;
}

.navigation-container-mobile .button-logout2 {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: auto;
  background-size: 40%;
}

.navigation-item-table {
  background-image: url("../shared/assets/menu-icons/shifts-table-icon.svg");
  background-size: 65%;
}

.navigation-item-tracker {
  background-image: url("../shared/assets/menu-icons/tracker-icon-white.svg");
  background-size: 60%;
}

.navigation-item-service {
  background-image: url("../shared/assets/menu-icons/service-icon.svg");
  background-size: 60%;
}

.navigation-item-apk {
  background-image: url("../shared/assets/menu-icons/apk-download-icon.svg");
  background-size: 50%;
}

.navigation-item-addition {
  margin: 0;
  margin-top: 10px;
  background-image: url("../shared/assets/menu-icons/addition-page-icon.svg");
  background-size: 90%;
}

.navigation-item-documentation {
  margin: 0;
  margin-top: 10px;
  background-image: url("../shared/assets/menu-icons/documentation-icon-white.svg");
  background-size: 50%;
}

.navigation-item-table-current {
  background-image: url("../shared/assets/menu-icons-current/shift-table-icon-current.svg");
}

.navigation-item-tracker-current {
  background-image: url("../shared/assets/menu-icons-current/tracker-icon-dark.svg");
  background-size: 50%;
}

.navigation-item-service-current {
  background-image: url("../shared/assets/menu-icons-current/service-icon-current.svg");
  background-size: 60%;
}

.navigation-item-apk-current {
  background-image: url("../shared/assets/menu-icons-current/apk-icon-current.svg");
}

.navigation-item-addition-current {
  margin: 0;
  margin-top: 10px;
  background-image: url("../shared/assets/menu-icons-current/addition-page-icon-current.svg");
  background-size: 90%;
}

.navigation-item-documentation-current {
  margin: 0;
  margin-top: 10px;
  background-image: url("../shared/assets/menu-icons-current/documentation-icon-current.svg");
  background-size: 50%;
}

.navigation-container-mobile .navigation-item-table {
  background-size: 50%;
}

.navigation-container-mobile .navigation-item-tracker {
  background-size: 40%;
}

.navigation-container-mobile .navigation-item-service {
  background-size: 50%;
}

.navigation-container-mobile .navigation-item-addition {
  margin-top: 0;
  margin-left: 10px;
  background-size: 80%;
}

.navigation-container-mobile .navigation-item-apk {
  background-size: 40%;
}

.navigation-container-mobile .navigation-item-table-current {
  background-size: 50%;
}

.navigation-container-mobile .navigation-item-tracker-current {
  background-size: 40%;
}

.navigation-container-mobile .navigation-item-service-current {
  background-size: 50%;
}

.navigation-container-mobile .navigation-item-addition-current {
  margin-top: 0;
  margin-left: 10px;
  background-size: 80%;
}

.navigation-container-mobile .navigation-item-apk-current {
  background-size: 40%;
}




