@use '../../../shared/styles/variables' as vars;

.management-navigation {
  display: flex;
  justify-content: space-between;

  &__item {
    display: flex;
    flex-direction: column;
    // width: 41%;
    height: 28vh;
    background-color: vars.$main-dark-color;
    color: #ffffff;
    border-radius: 20px;
    padding: 20px 15px;
  }

  &__item-title {
    position: relative;
    margin-top: 5px;
    margin-bottom: 30px;
    padding-left: 40px;
  }

  &__item-title::after {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 60%;
    content: "";
  }

  &__item-title--user::after {
    top: 35%;
    transform: translateY(-35%);
    height: 45px;
    background-image: url("../../../shared/assets/user-icon.svg");
  }

  &__item-title--equip::after {
    background-image: url("../../../shared/assets/equip-icon.svg");
  }

  &__item-count {
    font-size: 8rem;
    font-weight: 500;
    text-align: center;
  }
}

.management-navigation__item div {
  width: 10vw;
  margin: 0 auto;
  margin-top: auto;
  padding: 5px 0;
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  color: vars.$main-dark-color;
  background-color: vars.$main-light-color;
  border-radius: 20px;
}
