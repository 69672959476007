@use '../../../shared/styles/mixins' as mixins;
@use '../../../shared/styles/variables' as vars;

.start-navigation {

  &__wrapper {
    display: grid;
    grid-template-columns: 4fr 4fr 6fr 1fr 7fr;
    grid-template-rows: 1.5fr 0.5fr 2.5fr 5fr;
    gap: 35px;
    width: 100%;
    height: 100%;
  }

  &__item {
    position: relative;
    padding: 20px;
    background-color: #ffffff;
    @include mixins.rounded-corners(30px);
    opacity: 0;
    animation: fadeIn 0.5s forwards;
  }

  &__item--organization {
    grid-row: 2 / 4;
    grid-column: 1 / 2;
    background-color: #443B4E;
  }

  &__item--user {
    grid-column: 1 / 2;
    grid-row: 4 / 5;
    background-color: #443B4E;
    display: flex;
    flex-direction: column;
  }

  &__item--projects {
    grid-column: 2 / 4;
    grid-row: 1 / 4;
    padding: 30px 40px;
  }

  &__item--tracker {
    grid-column: 4 / 6;
    grid-row: 1 / 3;
  }

  &__item--shifts {
    grid-column: 2 / 3;
    grid-row: 4 / 5;
  }

  &__item--documentation {
    grid-column: 4 / 6;
    grid-row: 3 / 4;
  }

  &__item--management {
    grid-column: 3 / 5;
    grid-row: 4 / 5;
  }

  &__item--directions {
    grid-column: 5 / 6;
    grid-row: 4 / 5;
  }

  &__item--delay-1 {
    animation-delay: 0.1s;
  }

  &__item--delay-2 {
    animation-delay: 0.3s;
  }

  &__item--delay-3 {
    animation-delay: 0.5s;
  }

  &__item--delay-4 {
    animation-delay: 0.3s;
  }

  &__item--delay-5 {
    animation-delay: 0.5s;
  }

  &__item--delay-6 {
    animation-delay: 0.7s;
  }

  // &__link {
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   bottom: 0;
  //   left: 0;
  //   z-index: 5;
  // }

  &__link {
    display: block;
    text-decoration: none;
    color: vars.$main-dark-color;
  }

  &__item-title {
    margin: 0;
    margin-bottom: 20px;
    font-size: 1.8rem;
    font-weight: 500;
  }

  &__shift-table {
    width: auto;
    height: 35vh;
    background-image: url("../../../shared/assets/start-menu/map-start-menu-middle.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: 95%;
    border-radius: 20px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.organization-block {
  width: 130%;
  height: 80%;
  background-color: #443B4E;
  @include mixins.rounded-corners(30px);
}

.start-navigation__item--tracker .start-navigation__item-title {
  margin-bottom: 15px;
}
