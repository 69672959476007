@use "../../../shared/styles/variables.scss" as v;
@use "../../../shared/styles/mixins.scss" as m;

$max-width: 420px;

.edit-project-form {

  &__header {
    position: relative;
    box-sizing: border-box;
    margin-top: -0.5rem;
    padding: 2.2rem 0;
    width: 100%;
    height: 7.5rem;
    text-align: center;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    background-color: v.$main-light-color;

    &-text {
      @include m.fonts-basis($color: v.$main-dark-color, $weight: 600, $size: 2.4rem);
    }
  }

  &-main {
    box-sizing: border-box;
    width: 100%;
    padding: 3rem 8.2rem 5rem 8.2rem;
  }

  &-form {
    box-sizing: border-box;
    @include m.flex(column, $align: center);
    @include m.rounded-corners(30px);
    box-sizing: border-box;
    // padding: 5.6rem 8.2rem;
    color: v.$white-color;
    width: 57.4rem;
  }

  &-field {
    // width: 100%;
    @include m.flex(column, start);
    margin-bottom: 2.3rem;
    // max-width: $max-width;
  }

  &-row-container {
    @include m.flex($align: center);
    column-gap: 1.7rem;
  }

  &__button-wrapper {
    // display: inline-block;
    position: relative;
    width: 340px;
    margin: 0 auto;
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 1.5rem;
      left: 25%;
      @include m.box(3rem);
      @include m.background-image($url: "../../../shared/assets/plus-black-icon.svg", $size: 55%);
    }
  }
  
  &__create-project-button {
    width: 340px;
    padding: 1.8rem 8.7rem 1.8rem 11rem;
    background-color: v.$main-light-color;
    @include m.fonts-basis($weight: 500, $size: 2rem, $line-height: 2.3rem);
    @include m.rounded-corners(30px);
  }
}

.edit-project-form-form {

  &__button {
    @include m.rounded-corners();
    // padding: 1rem 2rem;
    cursor: pointer;
    font-size: 1.8rem;
    background-color: v.$main-light-color;
    color: v.$main-dark-color;
  }
}

.edit-project-form-field {
  
  &__label {
    @include m.fonts-basis($size: 2rem, $weight: 500);
    // @include m.font-size($size: 2rem, $line-height: 23.4px);
    // font-size: 2.4rem;
    margin-bottom: 1.2rem;
    margin-left: 1.7rem;
  }
  
  &__textarea {
    box-sizing: border-box;
    @include m.rounded-corners(20px);
    @include m.fonts-basis($size: 1.6rem, $weight: 500);
    width: 100%;
    max-width: 100%;
    min-height: 168px;
    max-height: 300px;
    padding: 1.5rem 2rem;
  }
  
  &__input {
    box-sizing: border-box;
    width: 100%;
    padding: 1.7rem 2rem;
    font-size: 1.6rem;
    border: none;
    @include m.rounded-corners(20px);
  }

  &_date-wrapper {
    position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 26%;
      right: 10%;
      padding: 0.5rem;
      @include m.square_obj(1.5rem);
      @include m.background-image($url: "../../../shared/assets/calendar-icon.svg", $size: 60%);
    }
  }

  &__button {
    background-color: v.$main-light-color;
    padding: 1.8rem;
    text-align: center;
  }
}

.edit-project-form__date-inputs {
  input {
    @include m.background-image($url: "../../../shared/assets/plus-black-icon.svg", $size: 55%);
  }
}