@use "../../shared/styles/variables.scss" as vars;

// body {
//   font-family: vars.$font-family;
//   font-size: vars.$font-size;
//   background-color: vars.$light-grey-color;
// }

.hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}