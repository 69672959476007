@use '../../../shared/styles/variables' as vars;
@use '../../../shared/styles/mixins' as mixins;

.projects-navigation {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;

  &__item {
    position: relative;
    min-width: 150px;
    height: 24vh;
    padding: 25px;
    background-color: vars.$main-dark-color;
    color: #ffffff;
    @include mixins.rounded-corners(30px);
  }

  &__item::after {
    position: absolute;
    right: 30px;
    bottom: 20px;
    width: 100px;
    height: 40px;
    background-image: url("../../../shared/assets/arrow-right-white.svg");
    background-repeat: no-repeat;
    background-position: center;
    content: "";
  }

  &__count {
    font-size: 8rem;
    font-weight: 500;
    line-height: 11.2rem;
  }

  &__description {
    font-size: 2rem;
    letter-spacing: 0.1rem;
  }
}
