@use '../../../shared/styles/variables' as vars;
@use '../../../shared/styles/mixins' as mixins;

.goto-arrow-btn {
  @include mixins.background-image($color: vars.$main-dark-color, $url: "../../../shared/assets/actions/arrow-right-white-icon.svg", $size: 57%);
  @include mixins.square_obj(3.5rem, 50%);
  
  &_rotated-right-bottom {
    transform: rotate(45deg);
  }
  
  &_rotated-left-top {
    transform: rotate(-145deg);
  }
}