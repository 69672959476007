@use '../../../shared/styles/mixins' as m;
@use '../../../shared/styles/variables' as v;

.header-documentation {
  @include m.flex(column);
  // @include m.one-hundred();
  height: 100%;
  box-sizing: border-box;
  padding: 2rem 3rem 4.2rem;

  &__upper {
    margin-bottom: 2rem;
  }

  &__header {
    display: inline-block;
    @include m.fonts-basis($size: 2.4rem, $weight: 600, $color: v.$main-dark-color);
    margin-right: 2rem;
  }

  &__project {
    display: inline-block;
    padding: 0.7rem 3rem;
    @include m.fonts-basis($size: 1.8rem, $weight: 500, $color: v.$main-dark-color);
    @include m.rounded-corners(35px);
    background-color: #716380;
  }

  &__button {
    margin-bottom: 1rem;
    width: 100%;
    // padding: 1rem;
    background-color: v.$main-dark-color;
    @include m.fonts-basis($size: 1.4rem, $weight: 500, $color: v.$white-color);
    @include m.rounded-corners(20px);
  }
}