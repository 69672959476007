@use "../../../shared/styles/variables.scss" as vars;
@use "../../../shared/styles/mixins.scss" as mixins;

$max-width: 420px;

.new-task-form {
  &-form {
    @include mixins.rounded-corners();
    display: grid;
    box-sizing: border-box;
    padding: 5.5rem 10rem;
    // height: 1000px;
    color: vars.$white-color;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(7, auto);
    column-gap: 8.4rem;

    &__button {
      @include mixins.rounded-corners();
      // padding: 1rem 2rem;
      cursor: pointer;
      font-size: 1.8rem;
      background-color: vars.$main-light-color;
      color: vars.$main-dark-color;

      &_selected {
        @include mixins.outline(3px, solid, vars.$white-color);
        color: vars.$white-color;
        background-color: vars.$main-dark-color;
      }
    }
  }

  &-field {
    @include mixins.flex(column, start);
    margin-bottom: 2.3rem;
    max-width: $max-width;
    
    &__label {
      @include mixins.fonts-basis($size: 2rem, $weight: 500);
      // @include mixins.font-size($size: 2rem, $line-height: 23.4px);
      // font-size: 2.4rem;
      margin-bottom: 1.2rem;
      margin-left: 1.7rem;
    }
    
    &__textarea {
      box-sizing: border-box;
      @include mixins.rounded-corners(20px);
      @include mixins.fonts-basis($size: 1.6rem, $weight: 500);
      width: 100%;
      max-width: $max-width;
      min-height: 100px;
      padding: 1.5rem 2rem;
    }
    
    &__input {
      box-sizing: border-box;
      width: 100%;
      padding: 1.7rem 2rem;
      font-size: 1.6rem;
      border: none;
      @include mixins.rounded-corners(20px);

      &::placeholder {
        @include mixins.fonts-basis($size: 1.6rem, $weight: 500, $color: vars.$placeholder-color);
      }
    }

    &__button {
      background-color: vars.$main-light-color;
      padding: 1.8rem;
      text-align: center;
    }
  }

  &-row-container {
    @include mixins.flex($align: center);
    column-gap: 1.7rem;
  }

  &-extra-info {
    &__textarea {
      min-height: 20rem;
      max-height: 28rem;
    }
    grid-column: 2;
    grid-row: -3 / span 2;
    height: 100%;
  }

  &-description {
    &__textarea {
      max-height: 168px;
    }
  }

  &-action-buttons {
    grid-column: 2;
    grid-row: -1;
  }

  &-submit-button {
    @include mixins.rounded-corners(30px);
    @include mixins.fonts-basis($size: 2rem, $weight: 600, $color: vars.$main-dark-color);
    width: 100%;
  }

  &-open-tasks-button {
    @include mixins.square_obj(5rem, 50%);
    @include mixins.background-image($color: vars.$main-light-color, $url: "../../../shared/assets/actions/open-tasks-list-icon.svg", $size: 60%);
  }

}
// .switcher__container .task-form-container {
//   visibility: hidden;
//   box-sizing: border-box;
//   position: absolute;
//   top: 250px;
//   right: 48px;
//   width: 0px;
//   height: 0px;
//   padding: 45px 60px;
//   background-color: var(--main-dark-color);
//   border-radius: 30px 0px 30px 30px;
//   transition: width 1.2s ease, height 1.2s ease, visibility 0.7s ease;
//   overflow: hidden;
// }

// .switcher__container .task-form-container.open {
//   visibility: visible;
//   height: 630px;
//   width: 1000px;
//   transition: width 1.2s ease, height 1.2s ease;
// }

// /* ALL TASKS CARD */

// .tracker-main-tasks-cards__container {
//   box-sizing: border-box;
//   width: 100%;
//   display: flex;
//   gap: 0.8rem;
//   height: 62vh;
//   max-height: 900px;
//   justify-content: space-between;
//   color: #ffffff;
// }


// .add-task-form {
//   display: grid;
//   grid-template-columns: 151px 46px 151px 77px 348px;
//   grid-template-rows: repeat(5, 52px);
//   row-gap: 51px;
// }

// .field-group {
//   position: relative;
//   margin: 0;
// }

// .add-task-form .field-group-worker,
// .add-task-form .field-group-area,
// .add-task-form .field-group-machinery {
//   grid-column: 1 / 4;
// }

// .add-task-form .field-group-start-date {
//   grid-column: 1 / 2;
// }

// .add-task-form .field-group-end-date {
//   grid-column: 3 / 4;
// }