@use "../../../shared/styles/_variables.scss" as vars;
@use "../../../shared/styles/mixins.scss" as mixins;

.task-card {
  box-sizing: border-box;
  @include mixins.flex(column);
  @include mixins.rounded-corners(15px);
  height: 130px;
  min-width: 200px;
  // width: 100%;
  width: 100%;
  margin-bottom: 1.5rem;
  // margin-right: 2rem;
  padding: 1.5rem 1.5rem 1rem 1rem;
  font-size: 1rem;
  color: vars.$main-dark-color;
  background-color: vars.$white-color;

  &__upper-section {
    @include mixins.flex($align: center);
  }

  &__button {
    @include mixins.square_obj($size: 20px, $round-corners: 50%);
    margin-right: 0.5rem;
    background-color: vars.$main-dark-color;
    background-size: 80%;
    box-shadow: 0px 5px 10px vars.$main-dark-color-shadow;
  }

  &__bottom-section {
    position: relative;
    @include mixins.flex($align: center);

    &_wrapper {
      @include mixins.flex($align: center);
    }
  }

  &__location {
    margin-left: 1rem;
    padding: 1.3rem 1.3rem 1rem;
    // vertical-align: middle;
    font-size: 0.9rem;
    background: url('../../../shared/assets/geo/location-icon.svg') no-repeat left;
  }
}

.direction {
  margin-right: 0.5rem;
  padding: 0.6rem 1rem;
  @include mixins.fonts-basis($size: 1rem, $weight: 500);
  @include mixins.rounded-corners(20px);
  text-transform: uppercase;
  text-align: center;
  box-shadow: 0px 2.5px 2.5px vars.$main-dark-color-shadow;
}

.dates {
  @include mixins.fonts-basis($size: 0.8rem, $weight: 600);
}

.staff-list {
  @include mixins.flex($align: center, $justify: start);

  &__item {
    padding: 0.2rem;
    @include mixins.square_obj(25px, 50%);
    object-fit: cover;
    margin-left: -1.5rem;
    background-color: gray;
    box-shadow: 0 4px 4px var(--main-dark-color-shadow);
  }
}

.change-status-button {
  @include mixins.background-image($color: null, $url: '../../../shared/assets/actions/change-status-icon.svg', $size: 60%);
}