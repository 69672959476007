@use '../../../shared/styles/variables' as vars;
@use '../../../shared/styles/mixins' as mixins;

.expand-btn {
  @include mixins.background-image($url: "../../../shared/assets/actions/arrow_down-black-icon-24.svg", $size: 90%);
  // @include mixins.square_obj(3.5rem);
  width: 3.5rem;
  
  &_white {
  @include mixins.background-image($url: "../../../shared/assets/actions/arrow_down-white-icon.svg", $size: 90%);
  }

  &_black {
  @include mixins.background-image($url: "../../../shared/assets/actions/arrow_down-black-icon-24.svg", $size: 90%);
  }

  transition: all 0.5s;

  &--expanded {
    transform: rotate(180deg);
  }
}