@use '../../../shared/styles/variables' as v;
@use '../../../shared/styles/mixins' as m;

.close-btn {
  @include m.background-image($url: "../../../shared/assets/actions/cross-black-icon.svg", $size: 80%);
  box-sizing: border-box;
  padding: 1.5rem;
  @include m.rounded-corners(50%);
  @include m.square_obj(35px, 50%);
  
  &_white {
  @include m.background-image($url: "../../../shared/assets/actions/cross-white-icon.svg", $size: 80%);
  }

  &_black {
  @include m.background-image($url: "../../../shared/assets/actions/cross-black-icon.svg", $size: 80%);
  }

  &_bg-dark {
    background-color: v.$main-dark-color;
  }

  &_bg-white {
    background-color: v.$white-color;
  }
}