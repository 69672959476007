:root {
  --main-dark-color: #211b27;
  --main-dark-color-shadow: rgba(33, 27, 39, 0.25);
  --main-light-color: #F0D07E;
}

P {
    margin: 0;
    padding: 0;
}

/* TRACKER */

.tracker-body-wrapper {
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 500;
}

.header-menu-service {
  background-image: url("../../Images/Menu/menu-service.svg");
}

.tracker-main__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
  width: 90vw;
  height: 90vh;
  max-width: 2200px;
  max-height: 1200px;
}

/* TRACKER HEADER */

.tracker-header__wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.tracker-header__item {
  box-sizing: border-box;
  position: relative;
  min-width: 250px;
  min-height: 180px;
  height: 215px;
  max-height: 215px;
  flex-grow: 1;
  margin-right: 20px;
  padding: 1rem;
  border-radius: 30px;
  border: 10px solid var(--main-dark-color);
}

.tracker-header__item:last-child {
  margin-right: 0;
}

.tracker-header__diagram {
  max-width: 300px;
}

.tracker-header__my-tasks {
  max-width: 550px;
}

/* GOTO BUTTON */

.tracker-goto-button {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  padding: 1.3rem;
  background-color: var(--main-dark-color);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
  border-radius: 50%;
}

.tracker-goto-arrow-button {
  background-image: url("../../Images/arrow-right-white.svg");
}

.tracker-goto-arrow-button.rotated-right-bottom {
  transform: rotate(45deg);
}

.tracker-goto-arrow-button.rotated-left-top {
  transform: rotate(-145deg);
}

/* TRACKER HEADER CHART */

.header-chart__wrapper {
  width: 100%;
}

/* PERIOD SWITCHER */

.switcher__container {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  /* align-self: center; */
  margin: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;
  max-height: 70px;
  width: 100%;
  font-size: 20px;
  font-weight: 500;
  background-color: #D4D2D6;
  border-radius: 30px;
}

.switcher__switcher {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  /* margin-right: 10%; */
}

.switcher-error-message__popup {
  opacity: 0;
  position: absolute;
  left: 112%;
  z-index: 10;
  bottom: -20%;
  font-size: 12px;
  font-weight: 500;
  padding: 0.5rem 1rem;
  width: 160px;
  color: #ffffff;
  background-color: rgb(184, 48, 54);
  border-radius: 30px;
  transition: opacity, 0.5s ease;
}

.switcher-error-message__popup.visible {
  opacity: 1;
}

.goto-button {
  position: absolute;
  width: 40px;
  height: 40px;
  transition: width 0.2s ease, height 0.2s ease;
}

.goto-button:hover {
  width: 43px;
  height: 43px;
}

.prev-week__goto-button:hover {
  right: 101%;
  top: -2px;
}

.next-week__goto-button:hover {
  left: 101%;
  top: -2px;
}
.prev-week__goto-button {
  /* left: -2.5rem; */
  right: 100%;
  top: -1px;
  background: url("../../Images/arrow-left.svg") no-repeat center;
  background-size: 100%;
  transition: right 0.2s ease;
}

.next-week__goto-button {
  left: 100%;
  top: -1px;
  background: url("../../Images/arrow-right.svg") no-repeat center;
  background-size: 100%;
  transition: left 0.2s ease;
}

.switcher-interval__wrapper {
  display: flex;
  justify-content: space-between;
  align-self: center;
}

.switcher-interval__date {
  border: none;
  font-size: 20px;
  letter-spacing: 0.1rem;
  /* font-weight: 500; */
  max-width: 170px;
  background: url("../../Images/calendar-icon.svg") no-repeat 95%;
}

.switcher-interval__date::-webkit-calendar-picker-indicator {
  /* display: none; */
  opacity: 0;
  padding: 0.5rem;
  /* padding-left: 0rem; */
  cursor: pointer;
}

.switcher-interval-dash {
  display: inline-block;
  width: 25px;
  height: 1px;
  background-color: var(--main-dark-color);
  align-self: center;
  margin: 0 0.7rem;
}

.switcher__new-task__button {
  position: relative;
  margin-right: 2rem;
  margin-left: 25%;
  padding: 0.8rem;
  padding-left: 2.8rem;
  width: 204px;
  height: 48px;
  border-radius: 20px;
  background-color: var(--main-light-color);
  font-size: inherit;
  font-weight: inherit;
  overflow: hidden;
  transition: background-color 0.5s ease, width  1s ease, margin 1s ease;
  box-shadow: 0 4px 10px 0 var(--main-dark-color-shadow);
}

@keyframes rotateToWhitePlus {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(90deg);
  }
}

@keyframes rotateToBlackPlus {
  0% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.switcher__new-task__button::before {
  content: "";
  position: absolute;
  left: 10%;
  top: 35%;
  width: 15px;
  height: 15px;
  background: url("../../Images/plus-black.svg") no-repeat center;
  /* animation: rotateToBlackPlus 1s ease; */
}

/* .switcher__new-task__button.open {
  width: 62px;
  height: 48px;
  background-color: var(--main-dark-color);
  color: #ffffff;
  font-size: 0;
  border-radius: 20px 20px 0 0;
  overflow: hidden;
  margin-left: 24rem;
}

.switcher__new-task__button.open::before {
  content: "";
  position: absolute;
  left: 1.5rem;
  bottom: 1rem;
  width: 15px;
  height: 15px;
  background: url("../../Images/close-cross-white.svg") no-repeat center;
  animation: rotateToWhitePlus 1s ease;
} */

/* SPECIFIED STYLES FOR TASK FORM */

.switcher__container .task-form-container {
  visibility: hidden;
  box-sizing: border-box;
  position: absolute;
  top: 250px;
  right: 48px;
  width: 0px;
  height: 0px;
  padding: 45px 60px;
  background-color: var(--main-dark-color);
  border-radius: 30px 0px 30px 30px;
  transition: width 1.2s ease, height 1.2s ease, visibility 0.7s ease;
  overflow: hidden;
}

.switcher__container .task-form-container.open {
  visibility: visible;
  height: 630px;
  width: 1000px;
  transition: width 1.2s ease, height 1.2s ease;
}

/* ALL TASKS CARD */

.tracker-main-tasks-cards__container {
  box-sizing: border-box;
  width: 90vw;
  display: flex;
  gap: 2rem;
  height: 62vh;
  max-height: 900px;
  color: #ffffff;
  padding-bottom: 1rem;
  overflow-x: scroll;
}

/* @media (max-width: 1300px) {
  .tracker-main-tasks-cards__container {
    flex-wrap: wrap;
  }
} */

.tasks-card {
  box-sizing: border-box;
  padding: 1.5rem 2rem 2.5rem 2rem;
  /* min-height: 80px; */
  min-height: 300px;
  max-height: 100%;
  min-width: 200px;
  max-width: 600px;
  flex-grow: 1;
  background-color: var(--main-dark-color);
  border-radius: 30px;
}

.tasks-card:last-child {
  margin-right: 0;
}

.tasks-card__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  margin-right: 2.5rem;
  font-weight: 400;
  /* width: 100%; */
}

.tasks-card-status {
  font-size: 28px;
}

.tasks-card-list__container {
  max-height: 92%;
  width: 100%;
  overflow-y: auto;
}

.tasks-card__list {
  margin: 0;
  padding: 0;
  width: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tasks-card-list__container::-webkit-scrollbar {
  width: 18px;
  background: #d9d9d9;
  border-radius: 10px;
}

.tasks-card-list__container::-webkit-scrollbar-thumb {
  background: var(--main-dark-color);
  border-radius: 15px;
  border: 3px solid transparent;
  background-clip: padding-box;
}

.task-card__item {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 130px;
  min-width: 200px;
  width: 470px;
  max-width: 100%;
  margin-bottom: 1rem;
  border-radius: 30px;
  padding: 0.7rem 0.8rem 0.8rem 2.2rem;
  color: var(--main-dark-color);
  background-color: #ffffff;
}

.card__info-general {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.info-direction {
  margin-right: 1rem;
  padding: 5px 18px;
  border-radius: 30px;
  font-size: 15px;
  text-transform: uppercase;
  box-shadow: 0px 4px 4px var(--main-dark-color-shadow);
}

.info-dates {
  font-size: 12px;
}

.card-button {
  width: 25px;
  height: 25px;
  margin-right: 0.5rem;
  background-color: var(--main-dark-color);
  border-radius: 50%;
  background-size: 80%;
  box-shadow: 0px 5px 10px var(--main-dark-color-shadow);
}

.card-button:last-child {
  margin-right: 0;
}

.edit-card__button {
  background: var(--main-dark-color) url("../../Images/edit-icon.svg") no-repeat center;
}

.delete-card__button {
  background: var(--main-dark-color) url("../../Images/bin.svg") no-repeat center;
  background-size: 50%;
}

.card__info-description {
  line-height: 19px;
  font-size: 16px;
  font-weight: 400;
}

.card__info-other {
  display: flex;
  align-items: center;
}

.task-card__item .card__info-other {
  margin-left: 15px;

}

.info-other-staff__list {
  margin: 0;
  padding: 0.1rem;
  list-style: none;
  display: flex;
  align-items:center;
  margin-right: 0.8rem;
}

.staff-item__avatar {
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 50%;
  margin-left: -15px;
  background-color: gray;
  box-shadow: 0 4px 4px var(--main-dark-color-shadow);
}

.staff-list__item:first-child {
  margin-left: 0;
}

.info-location {
  padding-left: 1.1rem;
  font-size: 14px;
  /* font-weight: 400; */
  background: url("../../Images/location-icon.svg") no-repeat left;
}

/* DETAILED CARD MODAL */

.info-block {
  box-sizing: border-box;
  background-color: var(--main-light-color);
  border-radius: 34px;
  min-height: 30px;
  padding: 0.7rem 1rem;
  box-shadow: 0px 5px 11px 1px var(--main-dark-color-shadow);
}

.card-modal__container {
  position: absolute;
  z-index: 10;
  display: flex;
  width: 100%;
  height: 100%;
  background-color: rgba(217, 217, 217, 0.4);
}

.card-modal__wrapper {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction:column;
  justify-content: space-between;
  width: 502px;
  min-height: 514px;
  max-height: 530px;
  margin: auto;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 30px;
}

.card-modal__wrapper p {
  margin: 0;
  padding: 0;
}

.card-modal__container .info-dates {
  font-size: 14px;
  font-weight: 600;
}

.card-modal__container .card__info-description {
  font-weight: 400;
  font-size: 18px;
}

.card-modal__container .card__info-other {
  flex-direction: column;
  align-items: baseline;
}

.card-modal__container .info-other-staff__list {
  width: 100%;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 0.3rem;
}

.card-modal__container .staff-list__item {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem 0.8rem;
  max-width: 200px;
  font-size: 12px;
  font-size: 10px;
}

.card-modal__container .staff-item__avatar {
  margin-left: 0px;
  margin-right: 0.5rem;
  width: 25px;
  height: 25px;
}

.card-modal__container .info-location {
  margin-top: 1rem;
  font-size: 19px;
}

.card-modal__container .info-about__task-type {
  margin-bottom: 0.5rem;
}

.card-modal__container .info-about__task-description {
  max-width: 90%;
  max-height: 110px;
  line-height: 19px;
  font-weight: 400;
  /* overflow: scroll; */
}

.card-modal__container .card__info-done-status {
  font-size: 14px;
  line-height: 16px;
}

.card-modal__container .card__info-equipment {
  display:flex;
  gap: 1rem;
}

.card-modal__container .info-equipment__equipment {
  min-width: 75px;
  max-width: 90px;
  padding-left: 2.5rem;
  font-size: 12px;
  font-weight: 400;
  background: var(--main-light-color) url("../../Images/Equipment/bulldozer_32.png") no-repeat 15%;
  background-size: 24px auto;
}

.some-action-one {
  right: 5rem;
  background-image: url("../../Images/sheet-icon.svg");
  background-size: 50%;
}

.some-action-two {
  right: 8rem;
  background-image: url("../../Images/hand-icon.svg");
}
