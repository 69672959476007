@use "../../../shared/styles/_variables.scss" as v;
@use "../../../shared/styles/mixins.scss" as m;

.project-card {
  position: relative;
  box-sizing: border-box;
  @include m.flex(column);
  @include m.rounded-corners(15px);
  // min-width: 200px;
  @include m.min-max-width(34rem, 38rem, 34rem);
  @include m.min-max-height(28rem, 35rem);
  // height: 249px;
  // width: 341px;
  margin-bottom: 1.5rem;
  // margin-right: 2rem;
  padding: 1.5rem 2.1rem;
  font-size: 1rem;
  color: v.$main-dark-color;
  background-color: v.$white-color;
  
  &__dates {
    @include m.fonts-basis($color: v.$placeholder-color, $size: 1.2rem, $weight: 600);
    margin-bottom: 0.5rem;
  }

  &__direction,
  &__name {
    margin-right: 0.5rem;
    padding: 0.6rem 1.8rem;
    max-width: 10rem;
    @include m.rounded-corners(20px);
    @include m.fonts-basis(1.5rem, 500);
    text-align: center;
    word-break: break-all;
    line-height: 1;
  }
  &__direction {
    background-color: #5690A3;
    color: v.$main-dark-color;
  }

  &__name {
    background-color: v.$main-dark-color;
    color: v.$white-color;
  }

  &__route {
    @include m.fonts-basis( $size: 2rem, $weight: 600, $line-height: 1.18);
  }

  &__status {
    @include m.flex($align: center);
    display: inline-flex;
    margin: 1.3rem 0;
    padding: 0.8rem;
    @include m.fonts-basis( $size: 1.2rem, $weight: 600, $line-height: 1.18);
    @include m.rounded-corners(15px);    
  }

  &__status-icon {
    margin-right: 1rem;
  }

  &__description {
    @include m.fonts-basis( $size: 1.6rem, $weight: 500, $line-height: 1.18);
  }

  &__tasks-statistic {
    @include m.fonts-basis($color: v.$placeholder-color, $size: 1.6rem, $weight: 500);
  }

  &__completion-date {
    @include m.fonts-basis($color: v.$placeholder-color, $size: 1.6rem, $weight: 500);
  }
  
  // &__upper-section {
  //   @include m.flex($align: center);
  // }

  &__button {
    @include m.square_obj($size: 2.7rem, $round-corners: 50%);
    margin-right: 0.5rem;
    background-color: v.$main-dark-color;
    background-size: 80%;
    box-shadow: 0px 5px 10px v.$main-dark-color-shadow;
  }

  &__bottom-section {
    position: relative;
    @include m.flex(column);

    &_wrapper {
      @include m.flex($align: center);
    }
  }
}

.direction {
  margin-right: 0.5rem;
  padding: 0.6rem 1rem;
  @include m.fonts-basis($size: 1rem, $weight: 500);
  @include m.rounded-corners(20px);
  text-transform: uppercase;
  text-align: center;
  box-shadow: 0px 2.5px 2.5px v.$main-dark-color-shadow;
}


.staff-list {
  @include m.flex($align: center, $justify: start);

  &__item {
    padding: 0.2rem;
    @include m.square_obj(25px, 50%);
    object-fit: cover;
    margin-left: -1.5rem;
    background-color: gray;
    box-shadow: 0 4px 4px var(--main-dark-color-shadow);
  }
}

.change-status-button {
  @include m.background-image($color: null, $url: '../../../shared/assets/actions/change-status-icon.svg', $size: 60%);
}