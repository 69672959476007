@use '../../../shared/styles/variables' as vars;

.directions-navigation {
  &__item {
    position: relative;
    padding: 10px 40px 20px;
    background-color: vars.$main-dark-color;
    color: #ffffff;
    border-radius: 20px;
  }

  &__item:first-child {
    margin-bottom: 20px;
  }

  &__item::after {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
    width: 100px;
    height: 40px;
    background-image: url("../../../shared/assets/arrow-right-white.svg");
    background-repeat: no-repeat;
    background-position: center;
    content: "";
  }

  &__count {
    font-size: 5rem;
  }

  &__item-title {
    font-size: 2rem;
  }
}
