.equip-white-excavator {
  background-image: url('../assets/eqipments-icons/excavator-white-icon.svg');
}

.equip-white-bulldozer {
  background-image: url('../assets/eqipments-icons/bulldozer-white-icon.svg');
}

.equip-white-crane {
  background-image: url('../assets/eqipments-icons/crane-wite-icon.svg');
}

.equip-white-grader {
  background-image: url('../assets/eqipments-icons/grader-white-icon.svg');
}

.equip-white-rink {
  background-image: url('../assets/eqipments-icons/rink-white-icon.svg');
}

.equip-white-truck {
  background-image: url('../assets/eqipments-icons/truck-white-icon.svg');
}

.equip-white-refueler {
  background-image: url('../assets/eqipments-icons/refueler-white-icon.svg');
}

.equip-white-auto {
  background-image: url('../assets/eqipments-icons/auto-white-icon.svg');
}

.equip-white-pooker {
  background-image: url('../assets/eqipments-icons/camera-white-icon.svg');
}

.equip-white-bus {
  background-image: url('../assets/eqipments-icons/bus-white-icon.svg');
}

.equip-white-tral {
  background-image: url('../assets/eqipments-icons/tral-white-icon.svg');
}

.equip-black-excavator {
  background-image: url('../assets/eqipments-icons/excavator-black-icon.svg');
}

.equip-black-bulldozer {
  background-image: url('../assets/eqipments-icons/bulldozer-black-icon.svg');
}

.equip-black-crane {
  background-image: url('../assets/eqipments-icons/crane-black-icon.svg');
}

.equip-black-grader {
  background-image: url('../assets/eqipments-icons/grader-black-icon.svg');
}

.equip-black-rink {
  background-image: url('../assets/eqipments-icons/rink-black-icon.svg');
}

.equip-black-truck {
  background-image: url('../assets/eqipments-icons/truck-black-icon.svg');
}

.equip-black-refueler {
  background-image: url('../assets/eqipments-icons/refueler-black-icon.svg');
}

.equip-black-auto {
  background-image: url('../assets/eqipments-icons/auto-black-icon.svg');
}

.equip-black-pooker {
  background-image: url('../assets/eqipments-icons/camera-black-icon.svg');
}

.equip-black-bus {
  background-image: url('../assets/eqipments-icons/bus-black-icon.svg');
}

.equip-black-tral {
  background-image: url('../assets/eqipments-icons/tral-black-icon.svg');
}