/* body {
  background-color: rgba(238, 238, 238, 1);
} */

/* .body-wrapper {
  display: flex;
  height: 100vh;
  background-color: #eeeeee;
}

.page-flex-wrapper {
  display: flex;
  align-items: center;
  margin: auto;
  background-color: #eeeeee;
} */

.service-main-index {
  flex:1;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  height: 95vh;
  /* height: 100%; */
  max-height: 1200px;
  width: 95vw;
  margin-right: 1vw;
}

.service-container {
  display: flex;
  height: 100%;
  margin: auto;
  width: 90%;
  flex-grow: 1;
}

.directions-container {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 1.5rem 3.5rem 0rem 3rem;
  background-color: #211b27;
  border-radius: 30px;
}

.directions-container h1{
  margin: 0;
  margin-left: 30px;
  margin-bottom: 20px;
  color: #ffffff;
  font-size: 28px;
  font-weight: 400;
}

.directions-wrapper {
  display: flex;
  height: 90%;
  width: 76vw;
  /* max-width: 1450px; */
  /* margin-top: 25px; */
  /* overflow-x: scroll; */
  transition: width 1s ease;
}

.directions-wrapper.collapsed {
  width: calc(84vw - 450px);
}

.directions-wrapper::-webkit-scrollbar {
  width: 23px;
  background: #d9d9d9;
  border-radius: 10px;
}

.directions-wrapper::-webkit-scrollbar-thumb {
  width: 10px;
  background: #211b27;
  border-radius: 15px;
  border: 5px solid transparent;
  background-clip: padding-box;
}

.button-add-direction {
  width: 80px;
  height: 90vh;
  max-height: 1200px;
  margin-left: 20px;
  font-size: 40px;
  transform-origin: right;
  color: #ffffff;
  background-color: #211b27;
  border-radius: 40px;
  box-shadow: 0px 7px 10px 0px rgba(0, 0, 0, 0.25);
  transition: width 1s ease;
}

.button-add-direction.open {
  width: 40vw;
  background-color: rgba(33, 27, 39, 1);
  color: rgba(33, 27, 39, 1);
}


/*DIRECTION BLOCK*/

.direction-block {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 100%; */
  max-height: 1000px;
  min-width: 560px;
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
  padding: 20px 35px;
  background-color: #f0f0f0;
  font-size: 18px;
  font-weight: 600;
  border-radius: 20px;
  overflow-y: auto;
}

.direction-block:last-child {
  margin-right: 0;
}

.direction-block::-webkit-scrollbar {
  width: 23px;
  background: #d9d9d9;
  border-radius: 10px;
}

.direction-block::-webkit-scrollbar-thumb {
  width: 10px;
  background: #211b27;
  border-radius: 15px;
  border: 5px solid transparent;
  background-clip: padding-box;
}

.direction-general-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.direction-general-info h1 {
  margin-right: 22px;
}

.direction-general-info .direction-item-link {
  color: #000000;
  text-decoration: none;
}

.direction-general-info .direction-item {
  margin: 0;
  margin-right: 15px;
  padding: 7px 30px;
  border-radius: 35px;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.chef-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 350px;
  margin-bottom: 20px;
  padding: 7px 25px;
  font-size: 16px;
  line-height: 19px;
  background-color: #F0D07E;
  border-radius: 40px;
  box-shadow: 0px 5px 10px 0px #00000040;
}

.chef-item p {
  margin: 0;
  margin-right: 20px;
}

.chef-item .service-form-input {
  height: 25px;
  width: 70px;
  margin-bottom: 0;
}

.chef-item .button-add-director {
  width: 25px;
  height: 25px;
  margin:  0 20px;
  background-color: #211B27;
  background-image: url("../../Images/tick1.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
  border-radius: 50%;
  box-shadow: 0px 5px 10px 0px #00000040;
}

.button-delete-direction {
  width: 50px;
  height: 50px;
  margin-left: auto;
  background-color: #211B27;
  background-image: url("../../Images/bin.svg");
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  box-shadow: 0px 5px 10px 0px #00000040;

}

.button-edit {
  width: 30px;
  height: 30px;
  background-color: #211B27;
  background-image: url("../../Images/edit.svg");
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  box-shadow: 0px 5px 10px 0px #00000040;

}

.direction-analytics {
  margin-bottom: 30px;
}

.analytics-item {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 10px 40px;
  background-color: #F0D07E;
  border-radius: 30px;
  box-shadow: 0px 4px 10px 1px #00000040;
}

.analytics-item p {
  margin: 0;
}

.button-open-analytics {
  width: 38px;
  height: 38px;
  background-color: #211B27;
  background-image: url("../../Images/arrow-open-list.svg");
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  box-shadow: 0px 5px 10px 0px #00000040;
}

.button-close-analytics {
  width: 38px;
  height: 38px;
  background-color: #211B27;
  background-image: url("../../Images/close-cross-white.svg");
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  box-shadow: 0px 5px 10px 0px #00000040;
}

.button-close-director-input {
  width: 25px;
  height: 25px;
  background-color: #211B27;
  background-image: url("../../Images/close-cross-white.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70%;
  border-radius: 50%;
  box-shadow: 0px 5px 10px 0px #00000040;
}

.direction-areas {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 500px;
}

.direction-area-wrapper {
  margin-right: 10px;
  margin-bottom: 15px;
}

.direction-area {
  margin: 0;
  padding: 9px 20px;
  font-size: 16px;
  line-height: 19px;
  font-weight: 500px;
  border-radius: 35px;
  box-shadow: 0px 5px 10px 0px #00000040;
}

.area-input-direction {
  display: flex;
  align-items: center;
  width: 210px;
  /* margin-top: 15px; */
  padding: 5px 5px 5px 15px;
  background-color: #211B27;
  border-radius: 30px;
}

.area-input-direction .service-form-input {
  width: 115px;
  height: 25px;
  margin: 0;
}

.area-input-direction .button-add-area {
  margin-bottom: 0;
}

.button-add-area {
  width: 30px;
  height: 30px;
  margin-left: 10px;
  margin-bottom: 15px;
  background-color: #211B27;
  background-image: url("../../Images/plus.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
  border-radius: 50%;
  box-shadow: 0px 5px 10px 0px #00000040;
}


/*FORM*/

.areas-form-wrapper {
  position: absolute;
  right: 5px;
  top: 95px;
  z-index: 2;
  width: 350px;
  height: 670px;
  padding: 60px 45px;
  background-color: transparent;
  border-radius: 30px;
}

.direction-form-wrapper {
  position: absolute;
  right: 45px;
  top: 85px;
  z-index: 2;
  width: 20vw;
  height: 75vh;
  /* padding: 60px 45px; */
  background-color: transparent;
  border-radius: 30px;
}

.button-close-form {
  position: absolute;
  z-index: 3;
  top: 5vh;
  right: 2vw;
  width: 45px;
  height: 45px;
  background-color: #F0D07E;
  background-image: url("../../Images/close-form-cross.svg");
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
}

.button-close-form-areas{
  top: 120px;
}

.direction-form {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.direction-form label {
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 15px;
  margin-left: 5px;
}

.service-form-input {
  box-sizing: border-box;
  height: 60px;
  margin-bottom: 15px;
  padding: 20px;
  font-size: 15px;
  font-weight: 500;
  border: none;
  border-radius: 25px;
}

.service-form-input-list {
  height: 40px;
}

.analytics-item.service-form-input {
  position: relative;
  z-index: 5;
}

.button-submit-direction {
  margin: 0;
  margin-top: auto;
  padding: 15px 40px;
  font-size: 12px;
  font-weight: 500;
  background-color: #F0D07E;
  border-radius: 30px;
  box-shadow: 0px 4px 10px 1px #00000040;
}

.new-button-yellow {
  background-color: #F0D07E;
  background-image: url("../../Images/plus-black.svg");
}

.analytics-list {
  position: absolute;
  top: 360px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 310px;
  height: 0;
  padding: 0 20px;
  font-weight: 500;
  overflow: hidden;
  background-color: #F0D07E;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  transition: height 1.5s ease;
}

.analytics-list-direction {
  position: relative;
  top:-20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 0;
  padding: 0 100px;
  font-weight: 500;
  overflow: hidden;
  background-color: #F0D07E;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  transition: height 1.5s ease;
}

.analytics-list.open {
  height: 250px;
  width: 310px;
  padding: 0 20px;
  background-color: #F0D07E;
}


.analytics-list-direction.open {
  height: 250px;
  padding: 0 100px;
  background-color: #F0D07E;
}

.analytics-list ul {
  width: 100%;
  height: 120px;
  padding: 0;
  margin: 0;
  margin-top: 50px;
  list-style-type: none;
  overflow-y: scroll;
}

.analytics-list-direction ul {
  width: 100%;
  height: 120px;
  padding: 0;
  margin: 0;
  margin-top: 50px;
  list-style-type: none;
  overflow-y: scroll;
}

.analytics-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}

.analytics-list-direction .analytics-list-item {
  padding: 0 100px 0 20px;
}

.remove-list-item-button {
  width: 16px;
  height: 16px;
  background-image: url("../../Images/close-form-cross.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.analytics-list ul::-webkit-scrollbar,
.analytics-list-direction ul::-webkit-scrollbar,
.areas-list::-webkit-scrollbar,
.equipment-list ul::-webkit-scrollbar,
.staff-members-list ul::-webkit-scrollbar {
  width: 15px;
  background: #d9d9d9;
  border-radius: 10px;
}

.analytics-list ul::-webkit-scrollbar-thumb,
.analytics-list-direction ul::-webkit-scrollbar-thumb,
.areas-list::-webkit-scrollbar-thumb,
.equipment-list ul::-webkit-scrollbar-thumb,
.staff-members-list ul::-webkit-scrollbar-thumb {
  width: 10px;
  background: #211b27;
  border-radius: 15px;
  border: 3px solid transparent;
  background-clip: padding-box;
}


.analytics-list p{
  margin: 80px 0 20px ;
}

.analytics-list li {
  margin-bottom: 5px;
}

.analytics-list .service-form-input {
  height: 40px;
  margin: 0;
  margin-top: 20px;
}


.button-add-list-item {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 13px 50px;
  color: #ffffff;
  background-color: #211B27;
  border-radius: 30px;
  cursor: pointer;
}

.areas-list {
  display: flex;
  flex-wrap: wrap;
  max-height: 100px;
  font-weight: 500;
  overflow-x: auto;
}

.area-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 5px;
  margin-bottom: 10px;
  padding: 10px 25px;
  background-color: #F0D07E;
  border-radius: 30px;
}

.area-item button {
  margin-left: 10px;
}

.area-input {
  display: flex;
  align-items: center;
  width: 210px;
  margin-top: 15px;
  padding: 5px 5px 5px 15px;
  background-color: #F0D07E;
  border-radius: 30px;
}

.area-input .service-form-input {
  width: 115px;
  height: 35px;
  margin: 0;
}

.button-add-area.new-button-yellow {
  margin-top: 15px;
  box-shadow: none;
}

.area-input .button-add-area.new-button-yellow {
  margin-top: 0;
  margin-bottom: 0;
}


/*AREAS*/

.button-close-areas {
  display: block;
  width: 65px;
  height: 65px;
  margin-bottom: 20px;
  margin-left: 20px;
  background-color: #211B27;
  background-image: url('../../Images/close-cross-white.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
  border-radius: 50%;
}

.button-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.direction-general-info .direction-item-areas {
  width: 20px;
  margin: 0;
  padding: 15px 40px;
  margin-right: 15px;
  border-radius: 35px;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.areas-wrapper {
  display: flex;
  height: 90%;
  width: 78vw;
  /* max-width: 1450px; */
  /* margin-top: 25px; */
  overflow-x: scroll;
  transition: width 1s ease;
}

.areas-wrapper.collapsed {
  max-width: 1100px;
}

.areas-wrapper::-webkit-scrollbar {
  width: 23px;
  background: #d9d9d9;
  border-radius: 10px;
}

.areas-wrapper::-webkit-scrollbar-thumb {
  width: 10px;
  background: #211b27;
  border-radius: 15px;
  border: 5px solid transparent;
  background-clip: padding-box;
}

.areas-block {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: 100%; */
  max-height: 1000px;
  min-width: 560px;
  margin-right: 1.5rem;
  margin-bottom: 1.5rem;
  padding: 20px 35px;
  background-color: #f0f0f0;
  font-size: 18px;
  font-weight: 600;
  border-radius: 20px;
  overflow-y: auto;
}

.areas-block:last-child {
  margin-right: 0;
}

.areas-block::-webkit-scrollbar {
  width: 23px;
  background: #d9d9d9;
  border-radius: 10px;
}

.areas-block::-webkit-scrollbar-thumb {
  width: 10px;
  background: #211b27;
  border-radius: 15px;
  border: 5px solid transparent;
  background-clip: padding-box;
}

.general-info-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* .area-section {
  position: relative;
  width: 100%;
  height: 260px;
  margin-bottom: 20px;
  background-color: #F0D07E;
  border-radius: 30px;
} */

.area-section:last-child {
  margin-bottom: 0;
}


.button-change-view {
  width: 40px;
  height: 40px;
  /* background-color: #F0D07E; */
  border-radius: 50%;
  background: #F0D07E url("../../Images/switching_arrows.svg") no-repeat center;
}

.equipment-item {
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 10px 40px;
  background-color: #F0D07E;
  border-radius: 30px;
  box-shadow: 0px 4px 10px 1px #00000040;
}

.areas-form-input {
  height: 60px;
  margin-bottom: 25px;
  padding: 0 20px;
  font-size: 15px;
  font-weight: 500;
  border: none;
  border-radius: 25px;
}
.equipment-item.areas-form-input{
  position: relative;
  z-index: 3;
}

.staff-members-list {
  position: absolute;
  top: 200px;
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 310px;
  height: 0;
  padding: 0 20px;
  font-weight: 500;
  overflow: hidden;
  background-color: #F0D07E;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  transition: height 1.5s ease;
}

.direction-form-wrapper .staff-members-list {
  top: 320px;
}

.staff-members-list.open {
  height: 270px;
  width: 310px;
  padding: 0 20px;
  background-color: #F0D07E;
}

.staff-members-list ul {
  width: 100%;
  height: 120px;
  padding: 0;
  margin: 0;
  margin-top: 50px;
  list-style-type: none;
  overflow-y: scroll;
}

.staff-member-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}

.staff-members-list li {
  margin-bottom: 5px;
}

.staff-members-list .service-form-input {
  height: 40px;
  margin: 0;
  margin-top: 10px;
  margin-bottom: 5px;
}

.button-type-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 100%;
  /* margin-top: 40px; */
}

.button-add-staff-member-type {
  margin-right: 6px;
  margin-bottom: 10px;
  padding: 7px 18px;
  font-size: 12px;
  color: #ffffff;
  background-color: #211B27;
  border-radius: 23px;
}

.button-add-staff-member-type:active,
.button-add-staff-member-type:focus {
  outline: 2px solid #ffffff;
}

.equipment-list {
  position: absolute;
  top: 280px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 310px;
  height: 0;
  padding: 0 20px;
  font-weight: 500;
  overflow: hidden;
  background-color: #F0D07E;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  transition: height 1.5s ease;
}

.direction-form-wrapper .equipment-list {
  top: 400px;
}

.equipment-list.open {
  height: 250px;
  width: 310px;
  padding: 0 20px;
  background-color: #F0D07E;
}

.equipment-list ul {
  width: 100%;
  height: 120px;
  padding: 0;
  margin: 0;
  margin-top: 50px;
  list-style-type: none;
  overflow-y: scroll;
}

.equipment-list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
}

.equipment-list li {
  margin-bottom: 5px;
}

.equipment-list .service-form-input {
  height: 40px;
  margin: 0;
  margin-top: 20px;
  margin-bottom: 15px;
}

.button-add-equipment {
  width: 38px;
  height: 38px;
  background-color: #211B27;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  background-size: 70%;
}

.button-add-equipment:active,
.button-add-equipment:focus {
  outline: 2px solid #ffffff;
}

.added-equipment-icon {
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70%;
}

.technic-type-white-excavator {
  background-image: url('../../Images/EquipmentWhite/excavator-white.svg');
}

.technic-type-white-bulldozer {
  background-image: url('../../Images/EquipmentWhite/bulldozer-white.svg');
}

.technic-type-white-crane {
  background-image: url('../../Images/EquipmentWhite/crane-wite.svg');
}

.technic-type-white-grader {
  background-image: url('../../Images/EquipmentWhite/grader-white.svg');
}

.technic-type-white-rink {
  background-image: url('../../Images/EquipmentWhite/rink-white.svg');
}

.technic-type-white-truck {
  background-image: url('../../Images/EquipmentWhite/truck-white.svg');
}

.technic-type-white-refueler {
  background-image: url('../../Images/EquipmentWhite/refueler-white.svg');
}

.technic-type-white-auto {
  background-image: url('../../Images/EquipmentWhite/auto-white.svg');
}

.technic-type-white-pooker {
  background-image: url('../../Images/EquipmentWhite/camera-white.svg');
}

.technic-type-white-bus {
  background-image: url('../../shared/assets/eqipments-icons/bus-white-icon.svg');
}

.technic-type-white-mixer {
  background-image: url('../../shared/assets/eqipments-icons/mixer-white-icon.svg');
}

.technic-type-white-mule {
  background-image: url('../../shared/assets/eqipments-icons/mule-white-icon.svg');
}

.technic-type-white-tractor {
  background-image: url('../../shared/assets/eqipments-icons/tractor-white-icon.svg');
}

.technic-type-white-tral {
  background-image: url('../../Images/EquipmentWhite/excavator-white.svg');
}

.technic-type-black-excavator {
  background-image: url('../../Images/Equipment/excavator_32.png');
}

.technic-type-black-bulldozer {
  background-image: url('../../Images/Equipment/bulldozer_32.png');
}

.technic-type-black-crane {
  background-image: url('../../Images/Equipment/crane_32.png');
}

.technic-type-black-grader {
  background-image: url('../../Images/Equipment/grader_32.png');
}

.technic-type-black-rink {
  background-image: url('../../Images/Equipment/rink_32.png');
}

.technic-type-black-truck {
  background-image: url('../../Images/Equipment/truck_32.png');
}

.technic-type-black-refueler {
  background-image: url('../../Images/Equipment/refueler_512.png');
}

.technic-type-black-auto {
  background-image: url('../../Images/Equipment/auto_32.png');
}

.technic-type-black-pooker {
  background-image: url('../../Images/Equipment/camera_10.png');
}

.technic-type-black-bus {
  background-image: url('../../Images/Equipment/bus_512.png');
}

.technic-type-black-tral {
  background-image: url('../../Images/Equipment/tral_512.png');
}

.technic-type-black-mixer {
  background-image: url('../../shared/assets/eqipments-icons/mixer-black-icon.svg');
}

.technic-type-black-mule {
  background-image: url('../../shared/assets/eqipments-icons/mule-black-icon.svg');
}

.technic-type-black-tractor {
  background-image: url('../../shared/assets/eqipments-icons/tractor-black-icon.svg');
}

@media (max-width: 1800px) {
  .analytics-item {
    padding: 10px 28px;
  }

  .analytics-item p {
    margin: 0;
  }

  .button-open-analytics {
    width: 28px;
    height: 28px;
  }

  .button-close-analytics {
    width: 28px;
    height: 28px;
  }
}

@media (max-width: 1600px) {

  .button-close-form {
    width:30px;
    height: 30px;
  }
    .button-type-list {
      margin-top: 5px;
    }
  }

  @media (max-width: 1400px) {
    .button-submit-direction {
      margin: 0;
      margin-top: auto;
      padding: 8px 20px;
      font-size: 12px;
    }
  }
