//на данный момент нигде не используется

@use "../../../shared/styles/_mixins.scss" as m;
@use "../../../shared/styles/variables" as v;

.objects-management-black-icon {
  @include m.background-image(
    $url: "../../../shared/assets/menu-icons/objects-management-black-icon.svg",
    $size: 100%
  );
}

.objects-management-white-icon {
  @include m.background-image(
    $url: "../../../shared/assets/menu-icons/objects-management-white-icon.svg",
    $size: 100%
  );
}

.resource-management-black-icon {
  @include m.background-image(
    $url:
      "../../../shared/assets/menu-icons/resources-management-black-icon.svg",
    $size: 100%
  );
}

.resource-management-white-icon {
  @include m.background-image(
    $url:
      "../../../shared/assets/menu-icons/resources-management-white-icon.svg",
    $size: 100%
  );
}

.download-white-icon {
  @include m.background-image(
    $url: "../../../shared/assets/menu-icons/download-white-icon.svg",
    $size: 80%
  );
}

.objects-management-black-icon,
.objects-management-white-icon,
.resource-management-black-icon,
.resource-management-white-icon,
.download-white-icon {
  @include m.box(3.2rem);
}
