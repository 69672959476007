@use '../../../shared/styles/mixins' as mixins;
@use '../../../shared/styles/variables' as vars;

.tasks-container {
  min-width: 31.7rem;
  width: 31.7rem;
  background-color: vars.$main-dark-color;
  @include mixins.rounded-corners();
  
  &__header {
  @include mixins.flex($align: center);
    margin: 2rem;
    // margin-right: 2.5rem;
    font-weight: 400;
    /* width: 100%; */
  }
  
  &:last-child {
    margin-right: 0;
  }
  
  &__status {
    font-size: 1.8rem;
  }

  &__amount {
    font-size: 1.4rem;
  }
}

.task-cards {
  margin: 0 2rem;
  height: 80%;
  max-height: 82%;
  overflow-y: auto;
  
  &__container {
    @include mixins.flex($direction: column, $align: center);
    padding-right: 2rem;
    overflow-x: none;
    overflow-y: none;
  }

  &::-webkit-scrollbar {
    width: 1.2rem;
    background: #d9d9d9;
    border-radius: 10px;
  }
  
  &::-webkit-scrollbar-thumb {
    background: var(--main-dark-color);
    border-radius: 13px;
    border: 3px solid transparent;
    background-clip: padding-box;
  }

}