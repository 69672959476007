.button {
  font-size: 1.2rem;
  font-weight: 500;
  padding: 8px 30px;
  @include rounded-corners(20px);
}

.button--primary {
  color: $main-dark-color;
  background-color: $main-light-color;
}

.button--secondary {
  color: #ffffff;
  background-color: $main-dark-color;
}

.button--tertiary {
  color: $main-dark-color;
  background-color: #D4D2D6;
}

.button--transparent {
  background-color: transparent;
}

.button--add {
  width: 25px;
  height: 25px;
  padding: 0;
  background-image: url("../assets/actions/plus.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
}

.button--add-transparent {
  width: 25px;
  height: 25px;
  padding: 0;
  background-color: transparent;
  background-image: url("../assets/actions/plus-black.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
  border: 2px solid #645F68;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
}

.button--close {
  width: 30px;
  height: 30px;
  padding: 0;
  background-image: url("../assets/actions/cross-black-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.button--edit {
  width: 30px;
  height: 30px;
  padding: 0;
  background-image: url("../assets/actions/edit.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 70%;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
}

.button--delete {
  width: 30px;
  height: 30px;
  padding: 0;
  background-image: url("../assets/actions/bin-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
}

.button--return {
  width: 30px;
  height: 30px;
  padding: 0;
  background-image: url("../assets/arrow-left-white.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50%;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
}

.button--tick {
  width: 30px;
  height: 30px;
  padding: 0;
  background-image: url("../assets/actions/tick-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40%;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);
}
