@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("../Fonts/Roboto-Regular.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("../Fonts/Roboto-Medium.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400; 
  src: url("../Fonts/Inter-Regular.woff2") format("woff2");
  font-display: swap;
}