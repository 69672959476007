@use "../../../shared/styles/variables" as v;
@use "../../../shared/styles/mixins" as m;

.switcher-between-pages {
  display: inline-flex;
  justify-content: center;
  /* min-width: 200px; */
  margin-right: 2rem;
  padding: 0.6rem 1rem;
  font-size: 1.2rem;
  background-color: v.$light-grey-color;
  border-radius: 10px;

  &-mobile {
    margin-right: 0;
    padding: 0.4rem 0.7rem;
    font-size: 1rem;
  }

  &__button,
  &__link {
    display: block;
    min-width: 7.5rem;
    padding: 1rem 2.4rem;
    color: v.$main-dark-color;
    background-color: v.$main-light-color;
    @include m.flex(
      $direction: row,
      $justify: center,
      $align: center,
      $wrap: nowrap
    );

    &--current,
    &__link--current {
      color: v.$light-grey-color;
      background-color: v.$main-dark-color;
    }
  }

  &__link {
    text-decoration: none;
    text-align: center;
  }

  &__text {
    @include m.fonts-basis(1.2rem, $weight: 500);
  }
}

.switcher-between-pages__button,
.switcher-between-pages__link {
  &:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  &:last-child {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}

// .link-switch-users {
//   border-top-left-radius: 20px;
//   border-bottom-left-radius: 20px;
// }

// .link-switch-equip {
//   border-top-right-radius: 20px;
//   border-bottom-right-radius: 20px;
// }
