@use '../../../shared/styles/variables' as vars;

.documentation-navigation  {
  margin-top: 15px;
  
  &__item {
    width: 100%;
    padding: 10px 0;
    background-color: vars.$main-dark-color;
    color: #ffffff;
    font-size: 1.4rem;
    border-radius: 20px;
    text-align: center;
  }

  &__item:first-child {
    margin-bottom: 10px;
  }
}
