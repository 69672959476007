.tracker-navigation {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 10px;
  width: 100%;

  &__item {
    position: relative;
    width: 70px;
    height: 11vh;
    min-height: 60px;
    padding: 5px;
    word-wrap: break-word;
    border-radius: 12px;
    font-size: 13px;
    font-weight: 500;
  }

  &__item--planned {
    background-color: #E5CB6D;
  }

  &__item--in-process {
    background-color: #6A89C6;
  }

  &__item--review {
    background-color: #7D69BA;
  }

  &__item--in-editing {
    background-color: #D49152;
  }

  &__item--done {
    background-color: #56A376;
  }

  &__item::after {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    content: "";
  }

  &__item--planned::after {
    background-image: url("../../../shared/assets/work-stages/planned.svg");
  }

  &__item--in-process::after {
    background-image: url("../../../shared/assets/work-stages/in-process.svg");
    background-size: 110%;
  }

  &__item--review::after {
    background-image: url("../../../shared/assets/work-stages/review.svg");
  }

  &__item--in-editing::after {
    background-image: url("../../../shared/assets/work-stages/in-editing.svg");
  }

  &__item--done::after {
    background-image: url("../../../shared/assets/work-stages/done.svg");
    background-size: 80%;
  }

  &__tasks-count {
    width: 50%;
    margin-left: 45%;
    font-size: 1rem;
    font-weight: 400;
    text-align: right;
  }

  &__item-title {
    margin-top: 3vh;
  }
}

.tracker-navigation__tasks-count strong{
  font-size: 1.1rem;
  font-weight: 600;
}
