@use '../../../shared/styles/variables' as vars;
@use '../../../shared/styles/mixins' as mixins;

.search-field {
  box-shadow: 0px 3px 7px 0px vars.$main-dark-color-shadow;
  &__colored-wrapper {
    position: relative;
    padding: 0.8rem 1.2rem;
    background-color: vars.$main-light-color;
    @include mixins.rounded-corners(23px);

    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0.8rem;
      right: 1.1rem;
      cursor: pointer;
      @include mixins.box($width: 3.8rem, $height: 2.8rem);
      @include mixins.rounded-corners(15px);
      @include mixins.background-image($color: vars.$main-dark-color, $url: "../../../shared/assets/loupe-white-icon.svg", $size: 40%);
    }
  }

  &__input {
    box-sizing: border-box;
    width: 325px;
    height: 2.8rem;
    padding: 1.4rem;
    @include mixins.rounded-corners(15px);
  }
}
