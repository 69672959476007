:root {
  --main-dark-color: #211b27;
  --main-light-color: #F0D07E;
  --liht-grey: #eeeeee;
  --darker-grey: #D4D2D6;
}

.table-row {
  position: relative;
  width: 97%;
  height: 40px;
  margin-bottom: 10px;
  padding: 3px;
  font-size: 11px;
  background-color: var(--darker-grey);
  border-radius: 30px;
}

.table-row-list {
  display: flex;
  align-items: center;
  align-self: center;
  margin: 0;
  padding: 0;
  width: 100%;
  list-style-type: none;
}

.table-row-item {
  position: relative;
  height: 34px;
  padding-top: 6px;
  background-color: #ffffff;
  overflow-x: auto;
}

.table-row-item-mobile {
  display: flex;
  align-items: center;
  font-size: 9px;
  overflow-y: hidden;
}

.table-row-item-mobile:not(:first-child) {
  padding-left: 20px;
}

.main-table-mobile .table-row-item-mobile:not(:first-child) {
  padding-left: 0;
}

.table-row-item::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.table-row-item p {
  margin-top: 8px;
  text-align: center;
}

.table-row-item:nth-child(1) p {
  margin-top: 0;
}

.table-row-item-mobile p {
  margin-bottom: 15px;
  text-align: start;
}

/* .table-row-item__imei-exists::after {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
  content: '';
  width: 15px;
  height: 15px;
  background-color: #73D173;
  border-radius: 50%;
  outline: 2px solid var(--main-dark-color);
} */

.table-row-item__imei-exists::after {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
  content: '';
  width: 20px;
  height: 20px;
  background-image: url("../../Images/imei.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.table-row-item-mobile .table-row-item__imei-exists::after {
  left: 7%;
  width: 10px;
  height: 10px;
}

.table-column-warranty {
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-column-warranty__button {
  display: flex;
  justify-content: space-between;
}

.table-column-warranty img {
  margin-right: -10px;
}

.table-column-warranty p  {
  margin-left: 25px;
  margin-right: 10px;
}

.table-row-item:first-child {
  width: 15%;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.table-row-item:nth-child(2) {
  width: 25%;
}

.table-row-item:nth-child(3) {
  width: 25%;
}

.table-row-item:last-child {
  position: relative;
  width: 35%;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.table-row-item-mobile:first-child {
  justify-content: center;
  width: 35%;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.table-row-item-mobile:nth-child(2) {
  width: 65%;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.table-row-item-mobile:nth-child(3) {
  width: 65%;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.table-row-item-mobile:last-child {
  position: relative;
  width: 15%;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
/*
.table-row-item:last-child::after {
  content: "";
} */

.table-row-item:not(:last-child)::before {
  position: absolute;
  top: 0;
  right: 0;
  content: "";
  width: 3px;
  height: 40px;
  background-color: var(--darker-grey);
}

.table-row-item-mobile:not(:first-child)::before {
  display: none;
}


.table-row-users .table-row-item:first-child {
  width: 30%;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.table-row-users .table-row-item:nth-child(2) {
  width: 20%;
}

.table-row-users .table-row-item:nth-child(3) {
  width: 25%;
}

.table-row-users .table-row-item:last-child {
  width: 25%;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.table-row-users .table-row-item-mobile:first-child {
  width: 45%;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

.table-row-users .table-row-item-mobile:nth-child(2) {
  width: 55%;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.table-row-users .table-row-item-mobile:nth-child(3) {
  width: 55%;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.table-row-users .table-row-item-mobile:last-child {
  width: 55%;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.users-equip-list {
  display: flex;
  width: 100%;
  justify-content: center;
  margin: auto;
  padding: 0 60px 0 20px;
  list-style-type: none;
}

.users-equip-item {
  margin-right: 5px;
  width: 40px;
}

.user-equip-item:last-child img {
  margin-right: 0;
}
