.modal-container {
  position: absolute;
  z-index: 10;
  display: flex;
  width: 100vw;
  height: 100vh;
  background-color: rgba(217, 217, 217, 0.4);

  &__wrapper {
    position: relative;
    margin: auto;
    background-color: var(--main-dark-color);
    border-radius: 30px;
  }
}

