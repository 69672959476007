@use "./_variables.scss" as v;
@use "./mixins.scss" as m;

.body-wrapper {
  display: flex;
  height: 100vh;
  width: 100vw;
  max-width: 1800px;
  background-color: #eeeeee;
}

.body-wrapper-mobile {
  margin-top: 30px;
}

.body-wrapper--flex {
  display: flex;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #eeeeee;
}

.body-wrapper--flex-mobile {
  flex-direction: column-reverse;
  justify-content: center;
  height: 95vh;
}

.main__wrapper {
  flex: 1 1;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  height: 95vh;
  width: 100%;
  max-height: 1200px;
}

.addition-main-index {
  flex: 1;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  height: 95vh;
  width: 100%;
  max-height: 1200px;
}

.page-container {
  display: flex;
  height: 100%;
  margin: auto;
  width: 87vw;
  flex-grow: 1;
}

.page-container--dark-bg {
  box-sizing: border-box;
  margin: auto 2vw auto 0vw;
  width: 100%;
  height: 100%;
  padding: 1.5rem 2.8rem 1.5rem 3rem;
  background-color: var(--main-dark-color);
  border-radius: 25px;
  border-right: 1px solid var(--main-dark-color);
}

.container-flex--space-between {
  @include m.flex($direction: row, $justify: space-between, $align: center, $wrap: nowrap);
}

.container-flex--column-space-between {
  @include m.flex($direction: column, $justify: space-between, $align: center, $wrap: nowrap);
}

.container-flex--centering {
  @include m.flex($direction: row, $justify: center, $align: center, $wrap: nowrap);
}

.container-flex--column-centering {
  @include m.flex($direction: column, $justify: center, $align: center, $wrap: nowrap);
}