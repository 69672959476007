.task-list-kc6 {

  &__item {
  position: relative;
  display: flex;
  width: 95%;
  margin-bottom: 20px;
  padding: 20px;
  background-color: #ffffff;
  border: 3px solid rgba(190, 190, 190, 1);
  border-radius: 10px;
  }

  &__item p {
    width: 80px;
    margin-right: 30px;
    word-wrap: break-word;
  }

  &__button--delete {
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%);
  }
}
