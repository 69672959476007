@use '../../../shared/styles/mixins' as mixins;

.section {

  width: 97%;
  margin-bottom: 20px;
  padding: 10px 0;
  background-color: #ffffff;
  // border: 3px solid rgba(190, 190, 190, 1);
  @include mixins.rounded-corners(20px);

  &__input-wrapper {
    display: flex;
    align-items: center;
    width: 95%;
    margin: 0 auto;
    padding-left: 35px;
    border: 2px solid rgba(190, 190, 190, 1);
    @include mixins.rounded-corners(5px);
  }

  &__input-wrapper--task {
    width: 99%;
    padding-left: 10px;
    margin-top: 25px;
  }

  &__input-wrapper--task input,
  &__input-wrapper--task textarea {
    min-width: 5%;
    height: 50px;
    padding: 10px;
    font-size: 1.1rem;
    border: none;
    @include mixins.rounded-corners(5px);
  }

  &__input-wrapper--task input:active,
  &__input-wrapper--task input:focus,
  &__input-wrapper--task textarea:focus,
  &__input-wrapper--task textarea:active {
    background-color: rgba(33, 27, 39, 0.5);
    outline: none;
  }

  &__input-wrapper-editing {
    padding-left: 35px;
    border: none;
  }

  &__input-wrapper-editing input::placeholder {
    font-size: 1.6rem;
  }

  &__input-wrapper-editing .section__input-name {
    width: 100%;

    margin-bottom: 10px;
    border: 2px solid #bebebe;
  }

  &__input-name {
    width: 30%;
    margin-right: 20px;
    padding: 15px 45px;
    @include mixins.rounded-corners(5px);
    outline: none;
  }

  &__input-name:active,
  &__input-name:focus {
    background-color: rgba(33, 27, 39, 0.5);
    outline: none;
  }

  &__button:last-child {
    margin-left: auto;
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 103%;
    margin-bottom: 40px;
  }

  &__header {
    display: flex;
    align-items: center;
    padding-right: 40px;
  }

  &__item .chapter__button,
  &__item h3 {
    margin-left: 80px;
  }

  &__item h3 {
    font-size: 1.6rem;
    font-weight: 500;
    color: rgba(33, 27, 39, 0.8);
    margin-bottom: 15px;
    margin-top: 15px;
    padding-top: 15px;
  }

  &__item::after {
    position: absolute;
    top: 100px;
    width: 100.25%;
    height: 2px;
    background-color: #bebebe;
    content: "";
  }

  &__button-wrapper {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 0;
  }
}
