@use "../../../shared/styles/_mixins.scss" as m;
@use "../../../shared/styles/variables" as v;

.navigation-container {
  position: relative;
  box-sizing: border-box;
  margin-inline: 1vw 1.5vw;
  margin-block: auto;
  padding: 4.5rem 1.7rem 2rem;
  width: 100px;
  height: min(1200px, 95vh);
  background-color: v.$main-dark-color;
  border-radius: 20px;
  box-shadow: 0 7px 10px v.$main-dark-color-shadow;
  transition: width 0.5s ease-in-out;

  &.expanded {
    width: 34rem;
    padding: 4.5rem 2.7rem 3.5rem;
  }

  &-mobile {
    position: fixed;
    z-index: 10;
    bottom: 20px;
    width: 90%;
    height: 50px;
    margin-block: 0;
    padding: 1rem 2.5rem;
  }
}

.navigation-menu {
  box-sizing: border-box;
  @include m.flex(column, $justify: start);
  @include m.one-hundred();

  &__list {
    position: relative;
    @include m.flex(column, $justify: start, $align: center);
    @include m.one-hundred();
    margin-top: 5vh;
    z-index: 2;
    gap: 1rem;

    &.expanded {
      @include m.flex(column, $justify: start, $align: stretch);
    }
  }

  &__sublist {
    @include m.one-hundred();

    padding-left: 1rem;
    border-left: 2px solid v.$white-color;
  }

  &__item {
    box-sizing: border-box;
    @include m.flex($justify: center, $align: center);
    gap: 1rem;
    padding: 1.5rem 1.3rem;
    @include m.fonts-basis(1.6rem, $color: v.$white-color, $weight: 600);
    @include m.box($width: 6.1rem, $radius: 10px);
    cursor: pointer;

    &--hovered {
      background-color: v.$white-color;
      color: v.$main-dark-color;
    }

    &-logout {
      @include m.flex($justify: center, $align: center);
      margin-top: auto;
    }

    &.active-item {
      background-color: v.$white-color;
      color: v.$main-dark-color;
    }

    &.expanded-item {
      @include m.flex($justify: start, $align: center);
      @include m.box($width: auto, $radius: 10px);
    }
  }

  &__link {
    @include m.rounded-corners(10px);
    display: block;
    box-sizing: border-box;
    padding-block: 1.2rem;
    padding-inline: 1.5rem 0;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: inherit;
  }
}

.navigation-menu__subitem {
  @include m.flex($align: center);
  @include m.fonts-basis(1.4rem, $color: v.$white-color, $weight: 600);
  @include m.rounded-corners(10px);

  &:hover,
  &.active-subitem {
    color: v.$main-dark-color;
    background-color: v.$white-color;

    &::after {
      @include m.pseudo-element($width: 3rem);
      content: "";
      position: static;
      @include m.background-image(
        $url: "../../../shared/assets/arrow-right-icon.svg",
        $size: 100%
      );
    }
  }

  &:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}

.navigation-menu__hovered-list {
  display: none;
  position: absolute;
  top: 7rem;
  left: 1rem;
  z-index: 1000;
  background-color: v.$white-color;
  flex-direction: column;
  @include m.rounded-corners(10px);
  width: 20rem;

  &__item {
    @include m.fonts-basis(
      $size: 1rem,
      $weight: 600,
      $color: v.$main-dark-color
    );
    padding: 1rem;
    margin-left: 1rem;
    border-left: 2px solid v.$main-dark-color;

    &:first-child {
      margin-top: 1rem;
    }
    &:last-child {
      margin-bottom: 1rem;
    }

    &:hover {
      background-color: v.$darker-grey-color;
    }
  }
}

.navigation-menu__item-container {
  position: relative;
}

.navigation-container-mobile .navigation-menu {
  flex-direction: row;
}

.navigation-container-mobile .navigation-menu__list {
  flex-direction: row;
  margin-top: 0;
  margin-left: auto;
}

.navigation-container-mobile .navigation-menu__item {
  width: 20px;
  height: 20px;
  margin-bottom: 0;
  background-size: 60%;
}

.navigation-container-mobile .navigation-menu__item:last-child {
  margin-top: 0;
}

.navigation-menu__toggle-button {
  @include m.pseudo-element($top: 2rem, $right: -1.5rem);
  @include m.box($width: 3rem, $radius: 50%);
  @include m.background-image(
    $color: #c6c5c7,
    $url: "../../../shared/assets/arrow-right-icon.svg",
    $size: 100%
  );
  z-index: 100;
  transition: 0.2s;

  &:hover {
    @include m.box($width: 3.3rem, $radius: 50%);
  }

  .rotated {
    transform: rotate(-180deg);
  }
}

.logo-monstr {
  overflow: hidden;
}
