@use "../../../shared/styles/variables.scss" as v;
@use "../../../shared/styles/mixins.scss" as m;

.dropdown-list {
  position: relative;
  color: v.$main-dark-color;

  &__field {
    position: relative;
    height: 35px;
    background-color: v.$main-light-color;
    @include m.fonts-basis($size: 1.7rem, $weight: 600);
    @include m.rounded-corners(28px);
    @include m.flex($align: center);
    padding: 1.5rem 2.8rem;
    z-index: 20;
    box-shadow: 0px 4px 10px 1px v.$main-dark-color-shadow;
  }

  &__expand-button {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    @include m.square_obj(3.5rem, 50%);
    @include m.background-image(
      $url: "../../../shared/assets/actions/arrow_down-icon.svg",
      $size: 90%
    );
    transition: all 0.7s;

    &:hover {
      background-color: v.$darker-grey-color;
    }
  }

  &__item-container {
    position: relative;
    @include m.flex($align: center);
  }

  &__list {
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    border: 1px solid black;
    overflow: hidden;
    // @include m.flex(column, center, center);
    height: 285px;
    width: 100%;
    // height: 0;
    background-color: v.$main-light-color;
    // transition: height 1.5s;
    z-index: 10;
    border-radius: 0px 0px 25px 25px;
    padding: 5rem 1.5rem 2rem;
    clip-path: inset(0 0 100% 0); // Полностью скрываем блок
    transition: clip-path 1.5s ease-out;

    &-list {
      padding-right: 1rem;
      max-height: 100%;
      overflow: auto;
    }

    &_opened {
      clip-path: inset(0 0 0 0); // Показываем блок
    }

    &-container {
      position: relative;
      background-color: v.$white-color;
      @include m.rounded-corners(28px);
    }
  }

  &__item {
    position: relative;
    z-index: 1;
    @include m.flex();
    background-color: v.$white-color;
    padding: 0.8rem 2rem;
    @include m.fonts-basis($size: 1.6rem, $weight: 500);
    @include m.rounded-corners(15px);
    margin-bottom: 0.7rem;

    // &:last-child {
    //   margin-bottom: 0rem;
    // }

    &-button {
      box-sizing: border-box;
      z-index: 1000;
      position: absolute;
      top: 0px;
      right: 2px;
      height: 90%;
      @include m.flex($justify: center, $align: center);
      // @include m.one-hundred();
    // padding: 0.8rem 2rem;

    }

    &:hover {
      background-color: v.$darker-grey-color;
    }
  }

  // &__delete-button {
  //   @include m.box(1.5)
  // }

  &__empty-message {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 2rem;
  }

  &__expand-button.chosen {
    @include m.background-image(
      $url: "../../../shared/assets/actions/arrow_down-white-icon.svg",
      $size: 45%
    );
  }
}

.dropdown-list-inner-list {
  box-sizing: border-box;
  width: 100%;
  margin-bottom: 1rem;
  padding: 1.2rem;

  &__item {
    @include m.border(3px, $color: v.$darker-grey-color);
  }
}

.chosen {
  background-color: v.$main-dark-color;
  color: v.$white-color;
}

.rotated {
  transform: rotate(180deg);
}
