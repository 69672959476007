@use '../../../shared/styles/variables' as vars;
@use '../../../shared/styles/mixins' as mixins;

.documentation {

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
  }

  &__list-header {
    position: relative;
    padding: 8px 20px;
    min-width: 150px;
    font-size: 1.2rem;
    font-weight: 500;
    // height: 35px;
    @include mixins.rounded-corners(30px);
    cursor: pointer;
  }

  &__list-header::after {
    content: "";
    position: absolute;
    top: 0;
    right: 5px;
    display: block;
    width: 33px;
    height: 33px;
    background: url("../../../shared/assets/actions/arrow_down-icon.svg") center/100% no-repeat;
  }

  &__list-header--projects {
    background-color: vars.$dark-violet;
  }

  &__title {
    margin: 1.5rem 2.5rem 1.8rem;
  }

  &__projects-list-container {
    position: relative;
  }

  &__projects-list {
    position: absolute;
    top: 30px;
    left: 50%;
    z-index: 10;
    transform: translateX(-50%);
    width: 80%;
    padding: 10px 20px;
    background-color: vars.$dark-violet;
    border-radius: 20px;
  }

  &__projects-item {
    padding: 10px 0;
    cursor: pointer;
  }

  &__projects-item:hover {
    background-color: lighten($color: vars.$dark-violet, $amount: 0.9);
  }

  &__nav-section {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  &__button-wrapper {
    margin-left: auto;
  }

  &__button:first-child {
    margin-right: 2rem;
  }

  &__table-container {
    overflow-x: auto;
  }

  &__table-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__table-header-list {
    display: flex;
    // grid-template-columns: repeat(9, 1fr);
    align-items: center;
    margin-right: 50px;
    padding: 20px 30px;
    // width: 80%;
    background-color: #D4D2D6;
    @include mixins.rounded-corners(30px);
  }

  &__table-header-additional {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 50px;
    padding: 20px 30px;
    width: 80%;
    background-color: vars.$main-light-color;
    @include mixins.rounded-corners(30px);
  }

  &__table-header-additional-list {
    justify-content: flex-start;
    padding: 0;
    padding-left: 25px;
  }

  &__table-header-item {
    width: 80px;
    margin-right: 30px;
    font-size: 1.1rem;
    font-weight: 500;
    color: rgba(33, 27, 39, 0.85);
  }

  &__table-header-additional-item {
    width: 120px;
    margin-right: 30px;
    font-size: 1.4rem;
    font-weight: 500;
    color: rgba(33, 27, 39, 0.85);
    text-align: center;
  }

  &__table-header-additional-input {
    margin-right: 10px;
    padding: 28px 10px;
    background-color: vars.$main-light-color;
    outline: none;
  }

  &__table-header-additional-input:active,
  &__table-header-additional-input:focus {
    background-color: vars.$main-light-color-hover;
    outline: none;
  }

  &__table-header-additional-input::placeholder {
    font-size: 1.1rem;
  }

  &__table-header-container .button {
    max-width: 120px;
    text-align: center;
    padding: 8px 20px;
  }

  &__table-header-additional-list .button,
  &__table-header-additional .documentation__button--edit {
    padding: 0;
  }

  &__button--add-chapter {
    margin-top: 30px;
    padding: 10px 25px;
    font-size: 1.4rem;
  }

  &__button--tick,
  &__button--edit {
    margin-right: 20px;
    margin-left: auto;
    padding: 0;
  }

  &__table-body-container {
    height: 50vh;
    overflow: auto;
  }
}
