.task-kc6 {
  width: 97.75%;
  background-color: #ffffff;
  border: 3px solid rgba(190, 190, 190, 1);
  border-radius: 10px;

  &__input-wrapper {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 10px;
  }

  &__input-wrapper input {
    width: 120px;
    margin-right: 20px;
    height: 50px;
    padding: 10px;
  }

  &__input-wrapper--task input:active,
  &__input-wrapper--task input:focus,
  &__input-wrapper--task textarea:focus,
  &__input-wrapper--task textarea:active {
    background-color: rgba(33, 27, 39, 0.5);
    border-radius: 5px;
    outline: none;
  }

  &__button--add {
    margin-left: auto;
    margin-right: 30px;
  }
}
